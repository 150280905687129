import instance from "..";

// export const viewAllExpert = () => instance.get("/api/backend/v1/experts/view");
export const viewAllExpert = () => instance.get("/api/backend/v1/experts/get/user");

export const viewExpert = (expertId) =>
  instance.get(`/api/backend/v1/expert/view/${expertId}`);

export const viewAllServices = () =>
  instance.get("/api/backend/v1/services/view?status=active");

export const addExpertApi = (data) =>{
  return instance.post("/api/backend/v1/expert/add", data);}

export const editExpertApi = (expertId, data) =>
  instance.patch(`/api/backend/v1/expert/edit/${expertId}`, data);

  export const uploadFilesApi = (data) =>
  instance.post(`/api/backend/v1/uploadFile`, data);

export const deleteExpertApi = (expertId) =>
  instance.delete(`/api/backend/v1/expert/delete/${expertId}`);
