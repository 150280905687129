import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import article from "../../img/article.jpg";
import Fade from "react-reveal/Fade";
import Reveal from "react-reveal/Reveal";
const Hero = (props) => {
  const [heroData, setHeroData] = React.useState([]);
  const [seconds, setSeconds] = useState(0);
  const [openModal, setOpenModal] = useState(true);

  const handleModal = () => {
    setOpenModal(false);
  };
  const createMarkup = (data) => {
    return { __html: data };
  };
  useEffect(() => {
    setInterval(() => {
      setSeconds(55);
    }, 2000);
  }, []);
  React.useEffect(() => {
    if (props !== undefined) {
      setHeroData(props.state);
      setSeconds((seconds) => seconds + 1);
    }
  }, [props]);
  return (
    <>
      <div className="waxon_tm_hero" id="home">
        {/* <img className="image" src={article} /> */}
        {props.state && (
          <div className={"background"}>
            <div className="leftpart"></div>
            <Fade left cascade>
              <div className="rightpart">
                <div className="inner">
                  {/* <img className="image" src={`${i.image}`} ></img> */}

                  <div
                    className="image"
                    style={{ backgroundImage: `url(${props.state[0].image})` }}
                  />
                  <div className={seconds > 0 ? "loaded" : "myOverlay"}></div>
                </div>
              </div>
            </Fade>
          </div>
        )}

        {props.state && (
          <div className="content">
            <div className="container">
              <div className="content_inner">
                <div className="name">
                  <div
                    className="dummy"
                    dangerouslySetInnerHTML={createMarkup(
                      props?.state[0]?.content == "<p>Welcome Ezentur</p>" ? 
                      "<p>Welcome Avaneeshkoyikkara</p>" : 
                      "<p>Welcome Ezentur</p>"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Hero;
