const authErrorChecker = (err, logout) => {
  if (err.response.status === 401) {
    logout();
  } else if (err.response.status === 403) {
    if (err.response.data.data.status === "hold") {
      logout();
    }
  }
  return null;
};

export default authErrorChecker;
