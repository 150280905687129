import { addOutletApi, editOutletApi } from '../api/outlet'
import authErrorChecker from '../utils/authErrorChecker'
import errorValidator from '../utils/errorValidator'

import resizeImage from '../utils/resizeImage'

async function getFormData(
  {
    title,
    categories,
    image,
    metaTitle,
    metaDescription,
    metaKeywords,
    callNow,
    whatsappNo,
    mapUrl,
    websiteUrl,
    description,
    slug,
    circles,
    localities,
    documents,
    deleteDocuments,
  },
  action
) {
  const formData = new FormData()
  formData.append('title', title)
  if (image?.length) {
    if (image[0].originFileObj) {
      const getNewImage = await resizeImage(image[0].originFileObj)
      formData.append('image', getNewImage)
    }
    action === 'edit' && formData.append('deleteImage', false)
  } else {
    action === 'edit' && formData.append('deleteImage', true)
  }
  metaTitle && formData.append('metaTitle', metaTitle)
  metaDescription && formData.append('metaDescription', metaDescription)
  circles?.forEach((item) => formData.append('circles[]', item))
  localities?.forEach((item) => formData.append('blocks[]', item))
  categories?.forEach((item) => formData.append('categories[]', item))
  formData.append('metaKeywords', metaKeywords)
  formData.append('call_now', callNow)
  whatsappNo && formData.append('whatsapp_no', whatsappNo)
  mapUrl && formData.append('map_url', mapUrl)
  websiteUrl && formData.append('website_url', websiteUrl)
  formData.append('description', description)

  // Documents
  // for (let i = 0; i < documents?.length; i++) {
  //   formData.append('documents', documents[i])
  // }

  documents.forEach((item) => formData.append('documents[]', item))

  // // deleteDocuments
  for (let i = 0; i < deleteDocuments?.length; i++) {
    formData.append('deleteDocuments[]', deleteDocuments[i])
  }

  if (action === 'edit') {
    formData.append('slug', slug || '')
  }
  return formData
}

export async function addOutlet(values, logout) {
  const formData = await getFormData(values, 'add')
  try {
    const responseJSON = await addOutletApi(formData)
    if (responseJSON.data.data) {
      return responseJSON.data.data
    }
    return null
  } catch (error) {
    // 403 HOLD, 401 -> error catcher
    authErrorChecker(error, logout)

    // Additional Error checker
    errorValidator(error?.response?.data || error?.response)

    return false
  }
}

export async function editOutlet(id, values, logout) {
  const formData = await getFormData(values, 'edit')
  try {
    const responseJSON = await editOutletApi(id, formData)
    if (responseJSON.data.success) {
      return true
    }
  } catch (error) {
    // 403 HOLD, 401 -> error catcher
    authErrorChecker(error, logout)

    // Additional Error checker
    errorValidator(error?.response?.data || error?.response)

    return false
  }
  return null
}
