import React, { useState,useRef } from "react";
import { Modal, Image, Container } from "react-bootstrap";

import Reactplayer from "react-player";
import "./Modal.css";
const Modals = ({ state, handleModal }) => {
  let [showModal, setShowModal] = React.useState(false);
  const [modalVisited, setModalVisited] = useState(true);
  const createMarkup = (data) => {
    return { __html: data };
  };
const contantRef = useRef(null)
  React.useEffect(() => {
    if (state !== undefined) {
      setShowModal(true);

    }
    setModalVisited(window.sessionStorage.getItem("modalVisited"));

  }, []);

  const closeModal = () => {
    setShowModal(false);
    window.sessionStorage.setItem("modalVisited", "null");

  };
   //console.log("statedfnsadf*",modalVisited)

  return (
    <>
      {
       state !== undefined && modalVisited === null ? (
          <>
            <div
              className="container"
              style={{ position: "absolute", marginTop: "5%" }}
            >
              <Modal size="lg" show={showModal} className="text-center">
                <Modal.Header className="text-center position-relative">
                  <Modal.Title>{state.title}</Modal.Title>
                  <button className="close" onClick={closeModal}><i class="fa fa-times"></i></button>
                </Modal.Header>
                <Modal.Body
                  style={{
                    "max-height": "calc(100vh - 210px)",
                    "overflow-y": "auto",
                  }}
                >
                  <>
                    {state.view_type === "image" && (
                      <Container

                        className="justify-content-center alert-image"
                      >
                        <Image src={state.image} />
                      </Container>
                    )}
                    {state.view_type === "video" && (
                      <Reactplayer
                        style={{ marginBottom: "10px" }}
                        width="100%"
                        height="300px"
                        url={state.videolink}
                      />
                    )}
                    <div
                      className="pt-4" ref = {contantRef}
                      dangerouslySetInnerHTML={createMarkup(state?.content?.replace('hii'," "))}
                    />
                  </>
                  <button onClick={closeModal} className="agree-button">
                    {" "}
                    Ok
                  </button>
                </Modal.Body>
              </Modal>
            </div>
          </>
        ) : (
          ""
        )
        // <div className="container" style={{ position: 'absolute', marginTop: '5%' }} >
        //    <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
        //       <Modal.Header closeButton>
        //          <Modal.Title>{state.title}</Modal.Title>
        //       </Modal.Header>
        //       <Modal.Body>
        //          <div dangerouslySetInnerHTML={createMarkup(state.content)} />
        //       </Modal.Body>
        //    </Modal>
        // </div>
      }
    </>
  );
};
export default Modals;
