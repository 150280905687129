import moment from "moment";
import React from "react";
import { Container, Image } from "react-bootstrap";
import ReactPlayer from "react-player";

const PreviewPage = ({ pageData }) => {
  const dateFormat = "YYYY-MM-DD dddd h:mm A";

  const createMarkup = (data) => {
    return { __html: data };
  };
  return (
    <>
      <Container>
        {!pageData.youtubeurl && (pageData.photo || pageData.image) && (
          <div
            style={{ marginBottom: "10px" }}
            className="justify-content-center"
          >
            <Image
              src={pageData.photo || pageData.image}
              style={{ width: "500px", height: "375px" }}
            />
          </div>
        )}
        {pageData.youtubeurl && (
          <div className="main_infos">
            <ReactPlayer
              controls
              url={pageData.youtubeurl}
              width="100%"
              height="300px"
            />
          </div>
        )}
      </Container>
      <div className="title">
        <h3 style={{ marginLeft: "40px" }}>{pageData.title}</h3>
      </div>
      <Container>
        <div className="short_info" style={{ color: "#000" }}>
          <ul style={{ display: "flex" }}>
            {pageData.publishing_time || pageData.publishedDate ? (
              <li style={{ margin: 0, listStyleType: "none" }}>
                <div className="list_inner">
                  {/* <img
                                  className="svg"
                                  src={
                                    require("../../img/svg/calendar.svg")
                                      .default
                                  }
                                  alt="calender"
                                /> */}
                  <span style={{ paddingLeft: 20 }}>
                    {moment(
                      new Date(
                        pageData.publishedDate || pageData.publishing_time
                      )
                    ).format(dateFormat)}
                  </span>
                </div>
              </li>
            ) : null}
            {pageData.publishing_time || pageData.publishedDate ? (
              <li
                className="bar-line"
                style={{
                  margin: 0,
                  paddingLeft: "20px",
                  listStyleType: "none",
                }}
              >
                |
              </li>
            ) : null}
            <li
              style={{
                margin: 0,
                paddingLeft: "12px",
                listStyleType: "none",
              }}
            >
              <div className="">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                <span style={{ paddingLeft: 6 }}>{pageData.location}</span>
              </div>
            </li>
            <li
              className="bar-line"
              style={{
                margin: 0,
                paddingLeft: "12px",
                listStyleType: "none",
              }}
            >
              |
            </li>
            <li
              style={{
                margin: 0,
                paddingLeft: "12px",
                listStyleType: "none",
              }}
            >
              <div className="">
                <i class="fa fa-user" aria-hidden="true"></i>
                <span style={{ paddingLeft: 6 }}>
                  {pageData.author || pageData.reporter}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </Container>
      <Container>
        <h5
          className="mt-3 mb-2"
          dangerouslySetInnerHTML={createMarkup(pageData?.description)}
        />
        <div
          className="blog_content"
          style={{
            fontSize: "20px",
          }}
          dangerouslySetInnerHTML={createMarkup(pageData?.content)}
        />
        {/* <div>
                          <span><b className="mr-2">Language</b>{article.language}</span>
                        </div> */}
        {/* <div>
                        <span><b className="mr-2">Location</b>{article.location}</span>
                    </div>
                    <div>
                        <span><b className="mr-2">Author</b>{article.author}</span>
                    </div> */}
        {/* <div>
                        <span><b className="mr-2">Link</b>{article.youtubeurl}</span>
                      </div> */}
      </Container>
    </>
  );
};

export default PreviewPage;
