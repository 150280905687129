import React from 'react';

const About = (props) => {
    const createMarkup = (data) => {
        return { __html: data };
    }

    return (
        <div className="waxon_tm_about mt-10" id="about">
            {props.state &&
                <div className="container">
                    <div className="about_inner">
                        <div className="left">
                            <img className="thumbnail" src={props.state.image[0]} />
                        </div>
                        <div className="right">
                            <div className="name">
                                <h3>{props.state.title?props.state.title:''}<span className="bg">About</span></h3>
                                <span >{props.state.sub_title?props.state.sub_title:''}</span>
                            </div>
                            <div className="text">
                                <div dangerouslySetInnerHTML={createMarkup(props?.state?.content)} />

                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default About;
