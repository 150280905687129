import React, { useEffect, useState } from "react";
import "./userArticle.css";

import Header from "../../../Header";
import Footer from "../../../Footer";
import noimage from "../../../img/No-Photo-Available.jpg";
// import { Link } from "react-router-dom";
import HeroImg from "../../../img/article.jpg";
// import { Helmet } from "react-helmet";
// import { NotFound } from "../404Error";
import {
  deleteArticleApi,
  viewAllArticle,
  viewArticle,
} from "../../../api/article";
import { Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { useData } from "../../../contexts/Datacontext";
import AddEditArticle from "./add-edit";
import { Link } from "react-router-dom";
import authErrorChecker from "../../../utils/authErrorChecker";
import errorValidator from "../../../utils/errorValidator";
import { useAuth } from "../../../contexts/auth-context";
import { Popconfirm } from "antd";
import { NotificationManager } from "react-notifications";
import Card from "../../../shared/Card";
import CustomModal from "../../../shared/Modal";
import PreviewPage from "../../../shared/PreviewPage/news-article";

const UserArticle = () => {
  const { logout } = useAuth();

  const { data } = useData();
  const [articleData, setArticleData] = useState([]);
  const [articleId, setArticleId] = useState(null);
  const [addEditArticleModal, setAddEditArticleModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [articlePreviewData, setArticlePreviewData] = useState(null);
  const [articlePreviewId, setArticlePreviewId] = useState(null);

  const refScroller = (data) => {
    console.log("scrol-data", data);
    const section = document.querySelector(`#${data}`);
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  function stripHtml(html) {
    var temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    let content =
      temporalDivElement.textContent || temporalDivElement.innerText || "";
    return content.slice(0, 100);
  }

  const getAllArticle = async () => {
    try {
      const resp = await viewAllArticle();
      console.log(resp.data, "the-resp");
      setArticleData(resp.data.data);
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);

      // Additional Error checker
      errorValidator(err.response.data || err.response);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    getAllArticle();

    // fetchServicesBySlug('');
  }, [addEditArticleModal]);

  const onDelete = async (articleId) => {
    try {
      const resp = await deleteArticleApi(articleId);

      if (resp.data.success) {
        const restArticleData = [...articleData].filter(
          (article) => article.id !== articleId
        );
        setArticleData(restArticleData);
        NotificationManager.success("Feature Deleted Successfully");
      }
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);

      // Additional Error checker
      errorValidator(err.response.data || err.response);
    }
  };

  // --------------------Preview Functionality----------------------------

  // Fetch Article by Id
  const fetchArticleById = async (articleId) => {
    setLoading(true);
    try {
      const res = await viewArticle(articleId);
      setArticlePreviewData(res.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);

      // Additional Error checker
      errorValidator(err.response.data || err.response);
    }
  };

  useEffect(() => {
    if (openPreviewModal) {
      if (articlePreviewId) {
        fetchArticleById(articlePreviewId);
      }
    }
  }, [articlePreviewId, openPreviewModal]);

  // --------------------Preview Functionality END----------------------------

  // const handleSearchChange = () => {

  //   const searchResult = articleData.filter(
  //     (expert) =>
  //       expert?.title?.toLowerCase().includes(evt.target.value.toLowerCase()) &&
  //       expert.status === "active"
  //   );
  //   setExperts(searchResult);
  // };

  return (
    <div>
      <Header refScroller={refScroller} data={data?.data} />

      <div className="waxon_tm_blog_single">
        <div className="hero classic" style={{ marginBottom: 40 }}>
          <div
            className="articleImg"
            style={{ backgroundImage: `url(${HeroImg})` }}
          />
        </div>

        <Container>
          <div className="waxon_tm_main_title">
            <div className="title" style={{ paddingTop: "30px" }}>
              <h3>
                Feature
                <span className="bg">Feature</span>
              </h3>
            </div>
          </div>
          <div
            className="add-icon-container"
            onClick={() => setAddEditArticleModal(true)}
          >
            <i className="fa fa-plus-circle add-icon"></i>
          </div>
          <AddEditArticle
            id={articleId}
            setShow={setAddEditArticleModal}
            show={addEditArticleModal}
            setArticleId={setArticleId}
          />

          {/* Preview Page*/}
          <CustomModal
            title="Preview Page"
            size="xl"
            setShow={setOpenPreviewModal}
            show={openPreviewModal}
          >
            <div style={{ minHeight: 400 }}>
              {loading ? (
                <div className="element-centering-position">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden"></span>
                  </Spinner>
                </div>
              ) : (
                articlePreviewData && (
                  <PreviewPage pageData={articlePreviewData} />
                )
              )}
            </div>
          </CustomModal>

          <div className="waxon_tm_news" style={{ marginBottom: 40 }}>
            <div className="news_inner">
              <Row>
                {articleData?.length > 0 ? (
                  articleData.map((article, j) => (
                    <Col lg="4" md="12" sm="12" xs="12">
                      <div>
                        <Card
                          data={article}
                          i={j}
                          setId={setArticleId}
                          setModal={setAddEditArticleModal}
                          onDelete={onDelete}
                          containerName="publishing_Page"
                          dataName="article"
                          setPreviewId={setArticlePreviewId}
                          setOpenPreviewModal={setOpenPreviewModal}
                        />
                      </div>
                    </Col>
                  ))
                ) : (
                  <div className="row">
                    <div className="col d-flex justify-content-center align-items-center">
                      <h3 style={{ fontWeight: "bold" }}>No Feature Found</h3>
                    </div>
                  </div>
                )}
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default UserArticle;
