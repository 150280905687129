import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react'

const size = {
  xs: '320px',
  sm: '768px',
  lg: '1200px',
}

const device = {
  xs: `min-width:${size.xs}`,
  sm: `min-width:${size.sm}`,
  lg: `min-width:${size.lg}`,
}
// const mapStyles = styled.
//https://maps.googleapis.com/maps/api/js
export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    })

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      })
    }
  }

  render() {
    console.log('props ', this.props)
    return (
      this.props.lat && (
        <Map
          google={this.props.google}
          onClick={this.onMapClicked}
          initialCenter={{
            lat: this.props.lat,
            lng: this.props.lng,
          }}
          // containerStyle={mapStyles}
          //maxHeight:'320px'
          style={{ width: '100%', height: '80%', minWidth: '320px' }}
        >
          <Marker onClick={this.onMarkerClick} name={'Current location'} />

          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <div>
              <h1>{this.state.selectedPlace.name}</h1>
            </div>
          </InfoWindow>
        </Map>
      )
    )
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDOl8ZkNSRq1HtYyEJpW2tdy2EfwxpedRo',
})(MapContainer)
