import React, { useState, useEffect } from 'react'
import { getMetaData } from './Components/api'
import { useHistory, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Flip from 'react-reveal/Flip'
import CustomModal from './shared/Modal/index'
import Login from './Components/Login'
import Register from './Components/Register'
import ResetPassword from './Components/ResetPassword'
import { useAuth } from './contexts/auth-context'
import { NavDropdown } from 'react-bootstrap'
import Profile from './Components/Profile'
import Avatar from 'react-avatar'
import { withRouter } from 'react-router-dom'

const Header = (props) => {
  const { authorized, user, logout } = useAuth()
  const location = useLocation()
  const [mobileView, setMobileView] = React.useState(false)
  const [bgColor, setbgColor] = React.useState('')

  const [seconds, setSeconds] = useState(0)

  // New feature
  const [openSigninModal, setOpenSignInModal] = useState(false)
  const [currentAuthModalRoute, setCurrentAuthModalRoute] = useState('login')
  const [openProfileModal, setOpenProfileModal] = useState(false)



  const handleMobile = () => {
    setMobileView(!mobileView)
  }
  let history = useHistory()
  const listenScrollEvent = (e) => {
    if (window.scrollY > 50) {
      setbgColor('white')
    } else {
      setbgColor('')
    }
  }
  let [state, setState] = React.useState([])

  React.useEffect(() => {
    getKeywords()
    window.addEventListener('scroll', listenScrollEvent)
  }, [])

  React.useEffect(() => {
    console.log(props.location.search, 'the-apth')
    if (props.location.search === '?openProfile=true') {
      setOpenProfileModal(true)
    }
  }, [props.location.pathname])

  const getKeywords = async () => {
    let res = await getMetaData()
    setState(res)
  }

  const handleClick = () => {
    history.push(`/`)
  }

  // this function will check the url is home or not is home it will smooth scroll
  const smoothScrollorNot = (path) => {
    if (window.location.pathname === '/') {
      return props.refScroller(path), history.push(`/#${path}`)
    } else {
      return window.location.assign('/'), (window.location.href = `/#${path}`)
    }
  }

  useEffect(() => {
    setInterval(() => {
      setSeconds(55)
    }, 2000)
  }, [location])

  return (
    <>
      <Helmet>
        <title>Avaneeshkoyikkara</title>
        <meta name="keywords" content={state?.page_keywords} />
        <meta name="description" content={state?.page_Description} />
      </Helmet>
      <div className={Number(seconds) > 30 ? 'loaded' : 'waxon_tm_preloader'}>
        <div className={Number(seconds) > 30 ? '' : 'spinner_wrap'}>
          <div className={Number(seconds) > 30 ? '' : 'spinner'}></div>
        </div>
      </div>

      <div className="waxon_tm_all_wrap" data-magic-cursor="" data-color="">
        <Flip cascade>
          <div
            className="waxon_tm_topbar"
            style={{
              backgroundColor:
                bgColor === '' && location.pathname !== '/'
                  ? 'rgba(255, 255, 255, 0.9)'
                  : bgColor,
            }}
          >
            <div className="container">
              <div className="topbar_inner">
                <div className="menu">
                  <img
                    style={{ width: 73, height: 73, marginRight: 20 }}
                    src={props?.data?.logo?.Logo || ''}
                  />

                  <div
                    className="links"
                    style={{
                      background: 'rgba(255,255,255,.5)',
                    }}
                  >
                    <ul
                      style={{
                        background: 'rgba(255,255,255,.5)',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <li onClick={() => history.push('/')}>
                        <a onClick={() => props.refScroller('home')}>
                          <span className="first">Home</span>
                          <span className="second">Home</span>
                        </a>
                      </li>
                      {/* <li>
                        <a onClick={() => smoothScrollorNot('services')}>
                          <span className="first">Services & Experts</span>
                          <span className="second">Services & Experts</span>
                        </a>
                      </li>
                      <li>
                        <a onClick={() => smoothScrollorNot('categories')}>
                          <span className="first">Products & Outlets</span>
                          <span className="second">Products & Outlets</span>
                        </a>
                      </li> */}
                      <li>
                        <a onClick={() => smoothScrollorNot('pages')}>
                          <span className="first">Pages & Features</span>
                          <span className="second">Pages & Features</span>
                        </a>
                      </li>
                      {/* <li>
                        <a onClick={() => smoothScrollorNot('editions')}>
                          <span className="first">Editions & News</span>
                          <span className="second">Editions & News</span>
                        </a>
                      </li> */}
                      <li>
                        <a onClick={() => smoothScrollorNot('about')}>
                          <span className="first">About</span>
                          <span className="second">About</span>
                        </a>
                      </li>
                      <li>
                        <a onClick={() => smoothScrollorNot('contact')}>
                          <span className="first">Contact</span>
                          <span className="second">Contact</span>
                        </a>
                      </li>

                      <li>
                        {!authorized ? (
                          <a onClick={() => setOpenSignInModal(true)}>
                            <span className="first">Sign in</span>
                            <span className="second">Sign in</span>
                          </a>
                        ) : (
                          <NavDropdown
                            title={
                              <Avatar
                                name={user.name}
                                size="40"
                                round={true}
                                textSizeRatio={1.5}
                              />
                            }
                            menuVariant="dark"
                          >
                            <NavDropdown.Item
                              onClick={() => setOpenProfileModal(true)}
                            >
                              View Profile
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            {/* <NavDropdown.Item
                              onClick={() => history.push('/user-expert')}
                            >
                              Experts
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              onClick={() => history.push('/user-outlet')}
                            >
                              Outlets
                            </NavDropdown.Item> */}
                            <NavDropdown.Item
                              onClick={() => history.push('/user-article')}
                            >
                              Features
                            </NavDropdown.Item>

                            {/* <NavDropdown.Item
                              onClick={() => history.push('/user-news')}
                            >
                              News
                            </NavDropdown.Item> */}

                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={logout}>
                              Logout
                            </NavDropdown.Item>
                          </NavDropdown>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Flip>

        <div className="waxon_tm_mobile_menu">
          <div className="topbar_inner">
            <div className="container bigger">
              <div className="topbar_in">
                <div className="trigger">
                  {/* Signin modal */}
                  <CustomModal
                    title={
                      currentAuthModalRoute === 'login'
                        ? 'Signin'
                        : currentAuthModalRoute === 'register'
                        ? 'Register'
                        : 'Reset Password'
                    }
                    setShow={setOpenSignInModal}
                    show={openSigninModal}
                    onClose={() => setCurrentAuthModalRoute('login')}
                  >
                    {currentAuthModalRoute === 'login' ? (
                      <>
                        <Login
                          setAuthRoute={setCurrentAuthModalRoute}
                          setOpen={setOpenSignInModal}
                        />
                      </>
                    ) : currentAuthModalRoute === 'register' ? (
                      <>
                        <Register setAuthRoute={setCurrentAuthModalRoute} />
                      </>
                    ) : (
                      <ResetPassword
                        setOpen={setOpenSignInModal}
                        setAuthRoute={setCurrentAuthModalRoute}
                      />
                    )}
                  </CustomModal>
                  {/*  */}

                  {/* Profile Modal */}

                  <CustomModal
                    title="Edit Profile"
                    setShow={setOpenProfileModal}
                    show={openProfileModal}
                  >
                    <Profile setShow={setOpenProfileModal} />
                  </CustomModal>

                  {/* <a> */}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '90vw',
                    }}
                  >
                    <div>
                      {mobileView ? (
                        <span onClick={() => handleMobile()}>
                          {' '}
                          <i className="fa fa-close"></i>
                        </span>
                      ) : (
                        <span className="menu" onClick={() => handleMobile()}>
                          <i className="fa fa-bars"></i>
                        </span>
                      )}

                      <img
                        style={{ width: 73, height: 73, marginLeft: 15 }}
                        src={props?.data?.logo?.Logo || ''}
                        alt="logo"
                      />
                    </div>
                    <div>
                      {!authorized ? (
                        <a onClick={() => setOpenSignInModal(true)}>
                          <span className="first">Sign in</span>
                        </a>
                      ) : (
                        <div className="mobile-nav-dashboard-dropdown">
                          <NavDropdown
                            title={
                              <Avatar name={user.name} size="40" round={true} />
                            }
                            menuVariant="dark"
                            style={{ zIndex: 1000 }}
                          >
                            <NavDropdown.Item
                              onClick={() => setOpenProfileModal(true)}
                            >
                              View Profile
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                              onClick={() => history.push('/user-article')}
                            >
                              Features
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item
                              onClick={() => history.push('/user-expert')}
                            >
                              Experts
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              onClick={() => history.push('/user-news')}
                            >
                              News
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              onClick={() => history.push('/user-outlet')}
                            >
                              Outlets
                            </NavDropdown.Item> */}
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={logout}>
                              Logout
                            </NavDropdown.Item>
                          </NavDropdown>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className={mobileView ? 'openDropdown' : 'dropdown'}>
            <div className="container">
              <div className="dropdown_inner">
                <ul style={{ listStyle: 'none' }}>
                  <li onClick={() => (handleClick(), handleMobile())}> Home</li>
                  {/* <li
                    onClick={() => (
                      props.refScroller('services'), handleMobile()
                    )}
                  >
                    Services & Experts
                  </li>

                  <li
                    onClick={() => (
                      props.refScroller('categories'), handleMobile()
                    )}
                  >
                    Products & Outlets
                  </li> */}

                  <li
                    onClick={() => (props.refScroller('pages'), handleMobile())}
                  >
                    Pages & Features
                  </li>

                  {/* <li
                    onClick={() => (
                      props.refScroller('editions'), handleMobile()
                    )}
                  >
                    Editions & News
                  </li> */}

                  <li
                    onClick={() => (props.refScroller('about'), handleMobile())}
                  >
                    About
                  </li>

                  <li
                    onClick={() => (
                      props.refScroller('contact'), handleMobile()
                    )}
                  >
                    Contact
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default withRouter(Header)
