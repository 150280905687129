import { Formik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import NumberFormat from "react-number-format";
import { viewProfileApi, editProfileApi } from "../../api/profile";
import errorValidator from "../../utils/errorValidator";
import { useAuth } from "../../contexts/auth-context";
import { userEditSchema } from "../../formValidationSchema";
import {
  userRequestValidateApi,
  userRequestVerificationApi,
} from "../../api/auth";
import authErrorChecker from "../../utils/authErrorChecker";
import { withRouter } from "react-router";
import { Tooltip } from "antd";

const Profile = ({ history, setShow }) => {
  const { settingUser, logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [userId, setUserId] = useState(null);
  const [profileEditStep, setProfileEditStep] = useState(true);
  const [emailVerificationId, setEmailVerificationId] = useState(null);
  const [phoneVerificationId, setPhoneVerificationId] = useState(null);
  const [isEmailVerfied, setIsEmailVerified] = useState(false);
  const [isPhoneVerfied, setIsPhoneVerified] = useState(false);

  const formikRef = useRef(null);

  useEffect(() => {
    const viewProfile = async () => {
      setLoading(true);
      try {
        const resp = await viewProfileApi();
        if (resp.data.success) {
          const profile = resp.data.data;
          setIsEmailVerified(profile.emailVerified);
          setIsPhoneVerified(profile.phoneVerified);
          setUserId(profile.id);
          formikRef.current.setFieldValue("name", profile.name);
          formikRef.current.setFieldValue("email", profile.email);
          formikRef.current.setFieldValue("phone", profile.phone);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);

        // 403 HOLD, 401 -> error catcher
        authErrorChecker(err, logout);

        // Additional Error checker
        errorValidator(err.response.data || err.response);
      }
    };

    viewProfile();
  }, []);

  const profileEditHandler = async (values, setSubmitting) => {
    let continueStep = false;
    if (values.password.trim().length > 0) {
      if (values.password === values.confirmPassword) {
        continueStep = true;
      } else {
        continueStep = false;
        NotificationManager.error("Passwords don't match", "Passwords");
      }
    } else {
      continueStep = true;
    }

    let sendValues = {};

    if (continueStep) {
      if (values.password.trim().length > 0) {
        sendValues = {
          name: values.name,
          email: values.email,
          phone: values.phone.replace(" ", ""),
          password: values.password,
        };
      } else {
        sendValues = {
          name: values.name,
          email: values.email,
          phone: values.phone.replace(" ", ""),
        };
      }

      try {
        const resp = await editProfileApi(sendValues);

        const user = resp.data.user;

        console.log(user, resp.data, "real-data");

        // Tempory state for needed for proccess
        setUserData(user);
        setUserId(user.id);

        // If user verified (phone and email)
        if (user.phoneVerified && user.emailVerified) {
          settingUser(user);

          NotificationManager.success("Updated Successfully");
          setShow(false);
        } else {
          loginVerficationHandler(user, values.email);
        }
        // Clearing username and password for entering 2nd step of asking token to enter
        formikRef.current.setValues({});
      } catch (err) {
        // 403 HOLD, 401 -> error catcher
        authErrorChecker(err, logout);

        // Additional Error checker
        errorValidator(err.response.data || err.response);
      }
    }
    setSubmitting(false);
  };

  const userValidateHandler = async (values, setSubmitting) => {
    let verified = false;

    if (emailVerificationId && emailVerificationId !== "done") {
      try {
        const resp = await userRequestValidateApi({
          userId: userId,
          verificationId: emailVerificationId,
          token: values.emailToken ? values.emailToken : "",
        });

        if (resp.data.success) {
          verified = true;
          setEmailVerificationId("done");
          delete formikRef.current.values.emailToken;
        }
      } catch (err) {
        verified = false;
        NotificationManager.error("Email: Invalid Token", "OTP");
      }
    }
    if (phoneVerificationId && phoneVerificationId !== "done") {
      try {
        const resp = await userRequestValidateApi({
          userId: userId,
          verificationId: phoneVerificationId,
          token: values.phoneToken ? values.phoneToken : "",
        });
        if (resp.data.success) {
          verified = true;
          setPhoneVerificationId("done");
          delete formikRef.current.values.phoneToken;
        }
      } catch (err) {
        verified = false;
        NotificationManager.error("Phone: Invalid Token", "OTP");
        console.log(err.response.data, "error email validation");
      }
    }

    if (verified) {
      settingUser(userData);
      NotificationManager.success("Verification Successfully");
      setShow(false);
    }
    setSubmitting(false);
  };

  const loginVerficationHandler = async (data, userName, resent) => {
    // @data -> When submiting correct login credentials we get user data back (data means user Data)
    // @userName -> userName
    // @resent -> Whether we want to resent the otp. This function will run again
    formikRef.current.setFieldValue({ emailToken: "" });
    formikRef.current.setFieldValue({ phoneToken: "" });
    // Clearing both Field (fixing some issue)

    if (
      !data.emailVerified ||
      !data.phoneVerified ||
      (!data.emailVerified && !data.phoneVerified)
      // If the user is not verified (phone or email)
    ) {
      // Email verification
      if (!data.emailVerified && emailVerificationId !== "done") {
        if (!data?.verifications?.email?.id || resent) {
          try {
            // We will call request verification Api
            const resp = await userRequestVerificationApi({
              userName: userName,
              verificationType: 1,
            });

            setEmailVerificationId(resp.data.data.verificationId);
            NotificationManager.success(`Email: ${resp.data.message}`, "OTP");
          } catch (err) {
            errorValidator(err.response.data || err.response);
            console.log(err.response.data);
          }
        } else {
          // If the verification data is present itself in the user data object
          setEmailVerificationId(data?.verifications?.email?.id);
          NotificationManager.success("Email: OTP Already Sent", "OTP");
        }
      }

      // Same process for phone verification
      if (!data.phoneVerified && phoneVerificationId !== "done") {
        if (!data?.verifications?.phone?.id || resent) {
          try {
            const resp = await userRequestVerificationApi({
              userName: userName,
              verificationType: 2,
            });
            setPhoneVerificationId(resp.data.data.verificationId);
            NotificationManager.success(`Phone: ${resp.data.message}`, "OTP");
          } catch (err) {
            errorValidator(err.response.data || err.response);
            console.log(err.response.data);
          }
        } else {
          NotificationManager.success("Phone: OTP Already Sent", "OTP");
          setPhoneVerificationId(data?.verifications?.phone?.id);
        }
      }
      document.querySelector(".emailTokenInput").value = "";
    }
  };

  useEffect(() => {
    // If any of validation Id exists we are going to 2nd step Token Verification
    if (emailVerificationId && emailVerificationId !== "done") {
      setProfileEditStep(false);
    }
    if (phoneVerificationId && phoneVerificationId !== "done") {
      setProfileEditStep(false);
    }
  }, [emailVerificationId, phoneVerificationId]);

  return (
    <div style={{ minHeight: 330, position: "relative" }}>
      <Formik
        innerRef={formikRef}
        initialValues={{ name: "", phone: "", email: "", password: "" }}
        validationSchema={profileEditStep ? userEditSchema : null}
        onSubmit={async (values, { setSubmitting }) => {
          if (values.name) {
            profileEditHandler(values, setSubmitting);
          } else {
            userValidateHandler(values, setSubmitting);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <>
            {loading ? (
              <div className="element-centering-position">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden"></span>
                </Spinner>
              </div>
            ) : (
              <form
                onSubmit={handleSubmit}
                className="mt-2 w-100"
                autoComplete="off"
              >
                {profileEditStep ? (
                  <>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Name"
                        id="name"
                        name="name"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.name}
                      />
                      <div className="error-msg">
                        {errors.name && errors.name}
                      </div>
                    </div>

                    <div
                      className="form-group"
                      style={{ position: "relative", display: "flex" }}
                    >
                      <Tooltip
                        title={
                          isEmailVerfied
                            ? "Email Verified"
                            : "Email Not Verified"
                        }
                      >
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Your Username/Email"
                          id="email"
                          name="email"
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          autoComplete="off"
                          value={values.email}
                        />
                      </Tooltip>
                      {isEmailVerfied ? (
                        <i
                          class="fa fa-check-circle"
                          style={{
                            color: "green",
                            position: "absolute",
                            right: 20,
                            bottom: 10,
                            fontSize: 25,
                          }}
                        ></i>
                      ) : (
                        <i
                          class="fa fa-times-circle"
                          style={{
                            color: "red",
                            position: "absolute",
                            right: 110,
                            bottom: 10,
                            fontSize: 25,
                          }}
                        ></i>
                      )}

                      {!isEmailVerfied && (
                        <button
                          type="button"
                          disabled={isSubmitting}
                          className="primary-btn"
                          style={{ margin: 0 }}
                          onClick={() =>
                            history.push(
                              `/user-verification?email=true&userId=${userId}`
                            )
                          }
                        >
                          Verify
                        </button>
                      )}

                      <div className="error-msg">
                        {errors.email && errors.email}
                      </div>
                    </div>

                    <div
                      className="form-group"
                      style={{ position: "relative", display: "flex" }}
                    >
                      <Tooltip
                        title={
                          isPhoneVerfied
                            ? "Phone Verified"
                            : "Phone Not Verified"
                        }
                      >
                        <NumberFormat
                          onValueChange={(e) => {
                    
                            setFieldValue("phone", e.formattedValue);
                          }}
                          style={{ width: "100%" }}
                          // customInput={FormInput}
                          // format="+1 (###) ###-####"
                          format={"+## ##########"}
                          allowEmptyFormatting
                          mask="_"
                          value={values.phone}
                          // value={values.phone ? formatMobileNumber(values.phone) : ''}
                          name="phone"
                          type="text"
                        />
                      </Tooltip>
                      {isPhoneVerfied ? (
                        <i
                          class="fa fa-check-circle"
                          style={{
                            color: "green",
                            position: "absolute",
                            right: 20,
                            bottom: 10,
                            fontSize: 25,
                          }}
                        ></i>
                      ) : (
                        <i
                          class="fa fa-times-circle"
                          style={{
                            color: "red",
                            position: "absolute",
                            right: 110,
                            bottom: 10,
                            fontSize: 25,
                          }}
                        ></i>
                      )}

                      {!isPhoneVerfied && (
                        <button
                          type="phone"
                          disabled={isSubmitting}
                          className="primary-btn"
                          style={{ margin: 0, marginLeft: 10 }}
                          onClick={() =>
                            history.push(
                              `/user-verification?phone=true&userId=${userId}`
                            )
                          }
                        >
                          Verify
                        </button>
                      )}
                      <div className="error-msg">
                        {errors.phone && errors.phone}
                      </div>
                    </div>

                    <div className="form-group">
                      <input
                        className="form-control"
                        placeholder="Enter Your Password"
                        type="password"
                        name="password"
                        autocomplete="new-password"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.password}
                      />
                      <div className="error-msg">
                        {errors.password && errors.password}
                      </div>
                    </div>

                    <div className="form-group">
                      <input
                        className="form-control"
                        placeholder="Enter Your Confirm Password"
                        type="password"
                        name="confirmPassword"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.confirmPassword}
                      />
                      <div className="error-msg">
                        {errors.confirmPassword && errors.confirmPassword}
                      </div>
                    </div>

                    <div className="d-flex-hr-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="primary-btn"
                      >
                        Update Profile
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <p>Verification Process</p>
                    {emailVerificationId && emailVerificationId !== "done" && (
                      <div className="form-group">
                        <input
                          className="form-control emailTokenInput"
                          placeholder="Email Token"
                          type="text"
                          name="emailToken"
                          //   value={values.emailToken}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    )}

                    {phoneVerificationId && phoneVerificationId !== "done" && (
                      <div className="form-group">
                        <input
                          className="form-control emailTokenInput"
                          placeholder="Phone Token"
                          type="text"
                          name="phoneToken"
                          //   value={values.phoneToken}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    )}

                    <div className="d-flex-hr-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="primary-btn"
                      >
                        Verify
                      </button>
                    </div>
                    <p
                      className="tc auth-switch-link"
                      onClick={() =>
                        loginVerficationHandler(userData, userData.email, true)
                      }
                    >
                      Resend OTP?
                    </p>
                  </>
                )}
              </form>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(Profile);
