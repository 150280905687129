import instance from "..";

// export const viewAllArticle = () => instance.get("/api/backend/v1/blog");
export const viewAllArticle = () => instance.get("/api/backend/v1/blog/user");

export const viewArticle = (articleId) =>
  instance.get(`/api/backend/v1/blog/${articleId}`);

export const viewAllPages = () => instance.get("/api/backend/v1/pages");

export const addArticleApi = (data) =>
  instance.post("/api/backend/v1/blog", data);

export const editArticleApi = (id, data) =>
  instance.patch(`/api/backend/v1/blog/${id}`, data);

export const deleteArticleApi = (articleId) =>
  instance.delete(`/api/backend/v1/blog/${articleId}`);
