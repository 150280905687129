import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { DatePicker, Icon, Input, Select, Upload, Button } from "antd";
import useUpload from "../../../../hooks/useUpload";
import { getBaseName } from "../../../../utils/getBaseName";
import moment from "moment";
import Editor from "../../../../shared/Editor";

import { addNews, editNews } from "../../../../services-add-edit/news";
import { NotificationManager } from "react-notifications";
import { useAuth } from "../../../../contexts/auth-context";
import { newsSchema } from "../../../../formValidationSchema";
import { v4 as uuidv4 } from "uuid";
import { docsFileAccept } from "../../../../utils/fileTypes";
import { DeleteOutlined, PaperClipOutlined } from "@ant-design/icons";
import { UploadFiles } from "../../../../services-add-edit/expert";

const FormArticle = ({
  data,
  editions,
  setShow,
  circleData,
  getBlocks,
  blocksData,
}) => {
  const [fileDocsList, setFileDocsList] = useState([]);
  const [deletedDocs, setDeletedDocs] = useState([]);
  const [docsToUpload, setDocsToUpload] = useState([]);
  const [initialDocs, setInitialDocs] = useState([]);
  const { logout } = useAuth();

  const formik = useFormik({
    initialValues: {
      publishingTime: new Date().toISOString(),
    },
    validationSchema: newsSchema,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values, { setSubmitting }) => {
      // Documents Things-----------
      // Check if the real binary file exists
      const sendingDocs = fileDocsList.filter(
        (cur) => "File" in window && cur instanceof File
      );
      console.log("sending docs are", sendingDocs);
      const uploadResponses = await Promise.all(sendingDocs.map(uploadToAWS));
      console.log("upload responses are", uploadResponses, initialDocs);

      values.documents = data
        ? [...uploadResponses, ...initialDocs]
        : uploadResponses;
      values.deleteDocuments = deletedDocs;
      // -----------
      const a = data?.id
        ? await editNews(data.id, values, logout)
        : await addNews(values, logout);
      // const a = await addBlog(values);
      if (a) {
        setShow(false);
        NotificationManager.success(
          data?.id ? "News Edited Successfully" : "News Added Successfully"
        );
      }
      setSubmitting(false);
    },
  });
  const dateFormat = "DD/MM/YYYY/ h:mm:ss a";

  const {
    onChange: onChangeMain,
    onRemove: onRemoveMain,
    beforeUpload: beforeUploadMain,
    fileList: fileListMain,
    setFileList: setFileListMain,
  } = useUpload(1);

  const propsMainImage = {
    listType: "picture",
    onChange: onChangeMain,
    onRemove: onRemoveMain,
    beforeUpload: beforeUploadMain,
    fileList: fileListMain,
  };

  useEffect(() => {
    if (data?.documents && !Array.isArray(data?.documents)) {
      // const arr = data.documents.slice(1, -1).split('","')
      const arr = data.documents
        .replace(/\\/g, "") // Remove backslashes
        .replace(/"/g, "") // Remove double quotes
        .slice(1, -1) // Remove the leading and trailing square brackets
        .split(","); // Convert to array
      setInitialDocs(arr);
      // data.documents = arr;
      console.log("arr value is", arr);
      // const final = data.documents.map(str => str.replace(/\\/g, ''));
    } else {
      setInitialDocs(data?.documents);
    }
  }, []);

  const removeFromDocs = (indexValue) => {
    setInitialDocs(initialDocs.filter((item, index) => indexValue != index));
  };

  console.log("file docs list", fileDocsList);

  const uploadToAWS = async (file) => {
    console.log("file to upload", file);

    const url = await UploadFiles(file);

    console.log("uploaded file and got url", url);
    return url;
  };

  useEffect(() => {
    console.log(data, "the-data");
    const initialValues = [
      "title",
      "metaTitle",
      "metaDescription",
      "metaKeywords",
      "location",
      "language",
      "content",
      "slug",
      "reporter",
    ];
    if (data?.id) {
      initialValues.forEach((cur) => {
        formik.setFieldValue(cur, data[cur]);
      });

      formik.setFieldValue("youtubeUrl", data.youtube_url);
      formik.setFieldValue("editionId", data.edition?.id);
      formik.setFieldValue("publishingTime", data.publishing_time);
      let circles = []
      if (data?.circles.length > 0) {
        circles = data?.circles?.map((cur) => cur.id)
      }

      getBlocks(circles)

      let blocks = []
      if (data?.blocks.length > 0) {
        blocks = data?.blocks?.map((cur) => cur.id)
      }
      formik.setFieldValue('circles', circles)
      formik.setFieldValue('localities', blocks)

      if (data?.image) {
        const image = [
          {
            uid: "-1",
            url: data.image,
            name: getBaseName(data.image),
            thumbUrl: data.image,
          },
        ];
        setFileListMain(image);
        formik.setFieldValue("image", image ? image : null);
      }

      setFileDocsList([]);
      setDeletedDocs([]);

      // if (data?.documents?.length > 0) {
      //   let availableDocs = []
      //   data?.documents?.forEach((doc) => {
      //     const availableDoc = {
      //       uid: uuidv4(),
      //       name: doc.split('_').pop(),
      //       status: 'done',
      //       url: `${process.env.BASE_URL}/${doc}`,
      //       path: doc,
      //       // thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      //     }
      //     availableDocs.push(availableDoc)
      //   })

      //   setFileDocsList(availableDocs)
      //   availableDocs = []
      // }
    }
  }, [data, editions]);

  useEffect(() => {
    if (fileListMain) formik.setFieldValue("image", fileListMain);
  }, [fileListMain]);

  const propsDocumentUpload = {
    onRemove: (file) => {
      const cloneFileList = [...fileDocsList];
      const index = cloneFileList.indexOf(file);
      const newFileList = cloneFileList.slice();
      newFileList.splice(index, 1);
      setFileDocsList(newFileList);

      if (file.uid) {
        setDeletedDocs((deletedDoc) => [...deletedDoc, file.path]);
      }
    },
    beforeUpload: (file, files) => {
      setFileDocsList([...fileDocsList, ...files]);
      return false;
    },
    fileList: fileDocsList,
    multiple: true,
  };

  const circlesHandler = (e) => {
    formik.setFieldValue("circles", e);
    getBlocks(e);
  };

  const localitiesHandler = (e) => {
    formik.setFieldValue("localities", e);
  };

  let formItems = [
    {
      type: (
        <Select
          value={formik.values?.editionId}
          name="editionId"
          placeholder="Select Edition"
          onChange={(e) => formik.setFieldValue("editionId", e)}
        >
          {editions?.map((i) => (
            <Select.Option key={i.id} value={i.id}>
              {`${i.title}`}
            </Select.Option>
          ))}
        </Select>
      ),
      key: "editionId",
      label: "Publishing Edition",
      error: formik.errors.editionId,
    },
    {
      type: (
        <Select
          value={formik.values.circles}
          mode="multiple"
          name="circles"
          placeholder="Select Circles"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            console.log(input, option, "gyhighi");
            return (
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={(e) => {
            circlesHandler(e);
          }}
        >
          {circleData?.map((i) => (
            <Select.Option key={i.id} value={i.id}>
              {`${i.name}`}
            </Select.Option>
          ))}
        </Select>
      ),
      key: "circles",
      label: "Select Circles",
      error: formik.errors.circles,
    },
    {
      type: (
        <Select
          value={formik.values.localities}
          mode="multiple"
          name="localities"
          placeholder="Select Localities"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            console.log(input, option, "gyhighi");
            return (
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={(e) => {
            localitiesHandler(e);
          }}
        >
          {blocksData?.map((i) => (
            <Select.Option key={i.id} value={i.id}>
              {`${i.name}`}
            </Select.Option>
          ))}
        </Select>
      ),
      key: "localities",
      label: "Select Localities",
      error: formik.errors.localities,
    },
    {
      type: (
        <Input
          value={formik.values.title}
          name="title"
          onChange={formik.handleChange}
        />
      ),
      key: "title",
      label: "News Title",
      error: formik.errors.title,
    },
    {
      type: (
        <DatePicker
          style={{ width: "100%" }}
          showTime={{ format: "HH:mm" }}
          format={dateFormat}
          value={moment(formik.values.publishingTime)}
          onChange={(e) =>
            formik.setFieldValue("publishingTime", new Date(e).toISOString())
          }
        />
      ),
      key: "publishingTime",
      label: "Publishing Time",
      error: formik.errors.publishingTime,
    },
    {
      type: (
        <>
          <Input
            value={formik.values.location}
            name="location"
            onChange={formik.handleChange}
          />
        </>
      ),
      key: "location",
      label: "Location",
      error: formik.errors.location,
    },
    {
      type: (
        <Input
          value={formik.values.reporter}
          onChange={formik.handleChange}
          name="reporter"
        />
      ),
      key: "reporter",
      label: "Reporter Name:",
      error: formik.errors.reporter,
    },
    {
      label: "Photo",
      error: formik.errors.image,
      key: "image",
      name: "image",
      type: (
        <>
          <Upload listType="picture-card" name="image" {...propsMainImage}>
            <Button>
              <Icon type="upload" /> Select File
            </Button>
          </Upload>
          <small>The image size should be 340px*240px</small>
        </>
      ),
    },
    {
      type: (
        <Input
          value={formik.values.youtubeUrl}
          name="youtubeUrl"
          onChange={formik.handleChange}
        />
      ),
      key: "youtubeUrl",
      label: "You Tube Url",
      error: formik.errors.youtubeUrl,
    },
    {
      type: (
        <Editor
          placeholder="Write something..."
          editorHtml={formik.values.content || ""}
          onChange={(e) => formik.setFieldValue("content", e)}
        />
      ),
      label: "Content",
      error: formik.errors.content,
      key: "content",
    },
    {
      type: (
        <Input
          value={formik.values.language}
          onChange={formik.handleChange}
          name="language"
        />
      ),
      key: "language",
      label: "Language",
      error: formik.errors.language,
    },
    {
      type: (
        <Input
          onChange={formik.handleChange}
          value={formik.values.metaTitle}
          name="metaTitle"
        />
      ),
      key: "metaTitle",
      label: "Meta Title",
      error: formik.errors.metaTitle,
    },
    {
      type: (
        <Input
          value={formik.values.metaDescription}
          onChange={formik.handleChange}
          name="metaDescription"
        />
      ),
      key: "metaDescription",
      label: "Meta Description",
      error: formik.errors.metaDescription,
    },
    {
      type: (
        <Input
          value={formik.values.metaKeywords}
          onChange={formik.handleChange}
          name="metaKeywords"
        />
      ),
      key: "metaKeywords",
      label: "Meta Keywords",
      error: formik.errors.metaKeywords,
    },
    {
      type: (
        <div>
          <Upload {...propsDocumentUpload} accept={docsFileAccept}>
            <Button>
              <Icon type="upload" /> Select Docs
            </Button>
          </Upload>
          {initialDocs?.length > 0 &&
            initialDocs.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // backgroundColor: '#EBF9FF',
                  alignItems: "center",
                  marginTop: "10px",
                  height: "20px",
                  transition: "background-color 0.3s",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = "#EBF9FF";
                  e.currentTarget.querySelector(".delete-icon").style.display =
                    "block";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = "";
                  e.currentTarget.querySelector(".delete-icon").style.display =
                    "none";
                }}
                className="doc-listing-div"
              >
                <div>
                  <PaperClipOutlined
                    style={{
                      marginLeft: "4px",
                      opacity: 0.6,
                      marginRight: "7px",
                    }}
                  />
                  <span
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      opacity: 0.8,
                    }}
                  >
                    {item.split("/").pop()}
                  </span>
                </div>
                <DeleteOutlined
                  className="delete-icon"
                  onClick={() => removeFromDocs(index)}
                  style={{
                    cursor: "pointer",
                    width: "13px",
                    height: "13px",
                    marginRight: "5px",
                    opacity: 0.7,
                    display: "none",
                  }}
                />
              </div>
            ))}
        </div>
      ),
      key: "documents",
      label: "Documents",
    },
  ];

  const slugOptions = data?.id
    ? [
        {
          type: (
            <Input
              value={formik.values.slug}
              name="slug"
              onChange={formik.handleChange}
            />
          ),
          key: "slug",
          label: "Slug",
          error: formik.errors.slug,
        },
      ]
    : null;

  if (data?.id) {
    formItems = slugOptions ? [...formItems, ...slugOptions] : [...formItems];
  }

  return (
    <form onSubmit={formik.handleSubmit} className="mt-2 w-100">
      {/* <div className="form-group"> */}

      {formItems?.map((item) => {
        return (
          <div className="form-group">
            <label style={{ display: "block", marginBottom: 10 }}>
              {item.label}
            </label>
            {item.type}
            <div className="error-msg d-block">{item.error && item.error}</div>
          </div>
        );
      })}

      <div className="d-flex-hr-center">
        <button
          type="submit"
          disabled={formik.isSubmitting}
          className="primary-btn"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default FormArticle;
