import instance from "..";

export const loginApi = (data) => instance.post("/api/backend/v1/login", data);

export const registerApi = (data) =>
  instance.post("/api/backend/v1/user/customer/register", data);

export const userRequestVerificationApi = (data) =>
  instance.post("/api/backend/v1/user/verification/token/request", data);

export const userRequestValidateApi = (data) =>
  instance.post("/api/backend/v1/user/verification/token/validate", data);

export const ResetPasswordApi = (userId, data) =>
  instance.put(`/api/backend/v1/user/${userId}/password/reset`, data);
