/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import queryString from "query-string";
import { withRouter } from "react-router";

const CustomModal = ({
  title,
  onClose,
  children,
  show,
  setShow,
  size,
  location,
  history,
}) => {
  console.log(show, "plsss");

  const parsed = queryString.parse(location.search);

  return (
    <>
      <Modal
        enforceFocus={false}
        backdrop='static'
        size={size || "md"}
        show={show}
        onHide={() => {
          setShow(false);
          onClose && onClose();
          // If need to remove that query string when closing the modal
          if (parsed.openProfile) {
            history.replace("/");
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ width: "100%", textAlign: "center" }}>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
};

export default withRouter(CustomModal);
