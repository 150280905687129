import instance from "..";

export const viewAllOutlets = () =>
  // instance.get("/api/backend/v1/outlets/view");
  instance.get("/api/backend/v1/outlets/view/user");

export const viewOutlet = (outletId) =>
  instance.get(`/api/backend/v1/outlet/view/${outletId}`);

export const viewAllCategories = () =>
  instance.get("/api/backend/v1/categories/view?status=active");

export const addOutletApi = (data) =>
  instance.post("/api/backend/v1/outlet/add", data);

export const editOutletApi = (outletId, data) =>
  instance.patch(`/api/backend/v1/outlet/edit/${outletId}`, data);

export const deleteOutletApi = (outletId) =>
  instance.delete(`/api/backend/v1/outlet/delete/${outletId}`);
