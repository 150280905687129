import instance from "..";

// export const viewAllNews = () => instance.get("/api/backend/v1/newss/view");
export const viewAllNews = () => instance.get("/api/backend/v1/newss/view/user");

export const viewNews = (newsId) =>
  instance.get(`/api/backend/v1/news/view/${newsId}`);

export const viewAllEditions = () =>
  instance.get("/api/backend/v1/editions/view?status=active");

export const addNewsApi = (data) =>
  instance.post("/api/backend/v1/news/add", data);

export const editNewsApi = (newsId, data) =>
  instance.patch(`/api/backend/v1/news/edit/${newsId}`, data);

export const deleteNewsApi = (newsId) =>
  instance.delete(`/api/backend/v1/news/delete/${newsId}`);
