import React,{useContext,useState,useEffect} from 'react';
import axios from 'axios';

const DataContext = React.createContext();

async function servicesGet() {
  const { data: response } = await axios.get('/api/backend/v1/home')
  return response;
}

export function useData(){
  return useContext(DataContext);
}

export function DataProvider({children}) {
  const [state, setstate] = useState({
    data:null
  });

  useEffect(() => {
    servicesGet().then(res => setstate({...state,data:res})).catch(
      e => console.log(e)
    );
  }, [])

  return(
    <DataContext.Provider value = {state}>
      {children}
    </DataContext.Provider>
  )
}
