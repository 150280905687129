import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import { getEditionsBySlug, homePageData } from "../api";
import noimage from "../../img/No-Photo-Available.jpg";
import HeroImg from "../../img/article.jpg";
import { Helmet } from "react-helmet";
import { Media, Image, Container } from "react-bootstrap";
import { useData } from "../../contexts/Datacontext";
import LoadScript from "react-load-script";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import moment from "moment";
import { CopyOutlined, ShareAltOutlined } from "@ant-design/icons";
import { NotificationManager } from "react-notifications";
import CopyToClipboard from "react-copy-to-clipboard";
import copyImg from "../../img/copy.png";
import { Spin } from "antd";

const News = (props) => {
  const dateFormat = "YYYY-MM-DD dddd h:mm A";
  const [news, setNews] = useState({});
  const [newsList, setNewsList] = useState([]);
  const [initialDocs, setInitialDocs] = useState([]);
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data } = useData();
  const { match } = props;
  const { params } = match;
  const { id } = params;
  // const [addthisLoaded, setAddthisLoaded] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handlePageData();
  }, []);

  const handlePageData = async () => {
    const res = await homePageData();
    //const favicon = document.getElementById('favicon')
    //if (favicon) favicon.href = res?.logo?.Logo
  };

  const createMarkup = (data) => {
    return { __html: data };
  };

  useEffect(() => {
    console.log("reloading ", id);
    if (
      window.location.search !== undefined &&
      typeof window.location.search !== "number"
    ) {
      setLoading(true);
      setNews({})
      fetchEditionsBySlug(window.location.search.slice(1));
    }
  }, [id]);

  // const handleAddthisLoaded = setInterval(function(){
  //   if(typeof addthis !== 'undefined') {
  //     clearInterval(handleAddthisLoaded);
  //     // eslint-disable-next-line no-undef
  //     addthis && addthis?.layers.refresh();
  //      console.log('interval');
  //     }
  //   },1000);

  const handleAddthisLoaded = () => {
    if (typeof addthis !== "undefined") {
      setTimeout(() => {
        try {
          // eslint-disable-next-line no-undef
          addthis && addthis.layers?.refresh();
        } catch (e) {}
      }, 1000);
    }
  };

  console.log("news id value is", id);

  const fetchEditionsBySlug = async (value) => {
    const response = await getEditionsBySlug(value);
    setFlag(true);
    // if (Object.keys(response)) {
    if (response?.length > 0) {
      // const newsList = response.published_data?.news.filter(
      const newsList = response?.filter(
        (news) => news.slug !== id && news.status === "active"
      );
      setNewsList(newsList);
      // const currentExpert = response.published_data.news.find(
      const currentExpert = response?.find(
        (news) => news.slug === id && news.status === "active"
      );
      if (currentExpert) {
        setNews(currentExpert);
        setLoading(false);
      }
    }
  };

  const handleChange = (news, index) => {
    props.history.push({
      pathname: `news/${news.slug}`,
      search: window.location.search.slice(1),
    });
  };
  const refScroller = (data) => {
    if (props.location.pathname === "/home") {
      const section = document.querySelector(`#${data}`);
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      props.history.push({
        pathname: "/",
        state: { detail: data },
      });
    }
  };

  useEffect(() => {
    if (news?.documents && !Array.isArray(news?.documents)) {
      // const arr = news.documents.slice(1, -1).split('","')
      const arr = news.documents
        .replace(/\\/g, "") // Remove backslashes
        .replace(/"/g, "") // Remove double quotes
        .slice(1, -1) // Remove the leading and trailing square brackets
        .split(","); // Convert to array
      setInitialDocs(arr);
      // news.documents = arr;
      console.log("arr value is", arr);
      // const final = news.documents.map(str => str.replace(/\\/g, ''));
    } else {
      setInitialDocs(news?.documents);
    }
  }, [news]);

  console.log("selected news", news);

  useEffect(() => {
    // Load ShareThis buttons
    if (window.__sharethis__) {
      window.__sharethis__.initialize();
    }
  }, [flag, news]);

  return (
    <>
      {/* <LoadScript
        url="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-60bb86f49dd949d8"
        onLoad={handleAddthisLoaded}
      /> */}
      <Helmet></Helmet>

      <Header refScroller={refScroller} data={data?.data} />

      <div className="waxon_tm_blog_single waxon_tm_news">
        <>
          <div className="hero classic">
            <div
              className="articleImg"
              style={{ backgroundImage: `url(${HeroImg})` }}
            />
          </div>

          <div className="container bigger" style={{ paddingLeft: "0px" }}>
            <div className="row">
              <div
                className="flex-box-item col-11 col-md-5 col-lg-5 mb-4 pb-4"
                style={{
                  backgroundColor: "#f5f6fa",
                  overflowX: "hidden",
                  overflowY: "auto",
                  height: "fit-content",
                }}
              >
                <h4 className="ml-2 pt-2">Related News</h4>
                {newsList?.length > 0 ? (
                  newsList.map((news, index) => (
                    <Media
                      key={news.id}
                      style={{
                        marginLeft: "15px",
                        paddingTop: "40px",
                        borderBottom: "2px solid #d3d3d3",
                      }}
                    >
                      <Media left href="#">
                        <>
                          <Link
                            to={{
                              pathname: `/news/${news.slug}`,
                              search: window.location.search.slice(1),
                            }}
                          >
                            {news.image ? (
                              <img
                                src={news.image}
                                alt="img"
                                width="90px"
                                height="80px"
                              />
                            ) : (
                              <img
                                src={noimage}
                                alt="img"
                                width="90px"
                                height="80px"
                              />
                            )}
                          </Link>
                        </>
                      </Media>
                      <Media body>
                        <div className="row">
                          <div className="col-12">
                            <Link
                              to={{
                                pathname: `/news/${news.slug}`,
                                search: window.location.search.slice(1),
                              }}
                            >
                              <Media
                                heading
                                style={{
                                  paddingLeft: "10px",
                                  fontWeight: "bold",
                                  color: "#333333"
                                }}
                              >
                                {news.title}
                              </Media>
                            </Link>
                          </div>
                          {/* <div className="col-12">
                            <div
                              style={{ paddingLeft: "10px" }}
                              dangerouslySetInnerHTML={createMarkup(
                                news.content
                                  .replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, "")
                                  .slice(0, 50) + "..."
                              )}
                            />
                          </div> */}
                          <div className="col d-flex">
                            {/* <button
                              onClick={() => handleChange(news, index)}
                              style={{
                                backgroundColor: "#4ab7ff",
                                marginLeft: "10px",
                                border: "none",
                                marginBottom: "4px",
                                color: "#fff",
                                borderRadius: "12px",
                              }}
                            >
                              Read More
                            </button> */}
                            <Link
                              to={{
                                pathname: `/news/${news.slug}`,
                                search: window.location.search.slice(1),
                              }}
                            >
                              <button
                                style={{
                                  backgroundColor: "#4ab7ff",
                                  marginLeft: "10px",
                                  border: "none",
                                  marginBottom: "4px",
                                  color: "#fff",
                                  borderRadius: "12px",
                                }}
                              >
                                View
                              </button>
                            </Link>
                          </div>
                        </div>
                      </Media>
                    </Media>
                  ))
                ) : (
                  <p className="text-center">No related News!!</p>
                )}
              </div>
              <div className="col mb-4">
                {/* {news && ( */}
                {Object.keys(news).length != 0 ? (
                  <>
                    <Container>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                      >
                        <div>
                          <ShareAltOutlined
                            style={{
                              fontSize: "30px",
                              color: "#08c",
                            }}
                          />{" "}
                          Share With Your Friends
                        </div>
                        {/* <CopyToClipboard
                      text={window.location.href}
                      onCopy={() => {
                        NotificationManager.success('Link Copied')
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <CopyOutlined
                          style={{
                            marginRight: 5,
                            fontSize: '30px',
                            color: '#08c',
                          }}
                        />{' '}
                        Copy Link
                      </div>
                    </CopyToClipboard> */}
                      </div>

                      {/* <div style={{ display: 'flex' }}>
                        <div
                          // props.addThisLoaded
                          class="addthis_inline_share_toolbox"
                          data-url={window.location.href}
                          data-title="Check out this URL"
                        ></div>
                        <CopyToClipboard
                          text={window.location.href}
                          onCopy={() => {
                            NotificationManager.success('Link Copied')
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer',
                              background: '#F7CE6D',
                              height: '50px',
                              width: '100px',
                              marginLeft: '5px',
                            }}
                          >
                            <img
                              src={copyImg}
                              alt="copy_link"
                              height={23}
                              width={23}
                              style={{ marginRight: '10px' }}
                            />{' '}
                            Copy
                          </div>
                        </CopyToClipboard>
                      </div> */}
                    </Container>
                    <div className="sharethis-inline-share-buttons"></div>
                    <Container>
                      {!news.youtube_url && news.image && (
                        <div
                          style={{ marginBottom: "10px", marginTop: "20px" }}
                          className="justify-content-center"
                        >
                          <Image
                            src={news.image}
                            // style={{ width: '500px', height: '375px' }}
                            style={{ width: "auto", height: "375px" }}
                          />
                        </div>
                      )}
                      {/* <div
                        className="blog_content"
                        style={{
                          fontSize: "20px",
                        }}
                      /> */}

                      {/* <h5 className="mt-3 mb-2">News details</h5> */}
                      {/* <div>
                        <span><b className="mr-2">Language</b>{news.language}</span>
                      </div>
                      <div>
                        <span><b className="mr-2">Location</b>{news.location}</span>
                      </div>
                      <div>
                        <span><b className="mr-2">Reporter</b>{news.reporter}</span>
                      </div> */}

                      {news.youtube_url !== null && news.youtube_url !== "" && (
                        <div className="main_infos">
                          <ReactPlayer
                            controls
                            url={news.youtube_url}
                            width="100%"
                            height="300px"
                          />
                        </div>
                      )}
                    </Container>
                    <div className="title">
                      <h3 style={{ marginLeft: "40px", marginTop: "7px" }}>
                        {news.title}
                      </h3>
                    </div>
                    <Container>
                      <div className="short_info" style={{ color: "#000" }}>
                        <ul style={{ display: "flex" }}>
                          <li style={{ margin: 0, listStyleType: "none" }}>
                            <div className="list_inner">
                              <img
                                className="svg"
                                src={
                                  require("../../img/svg/calendar.svg").default
                                }
                                alt="calender"
                              />
                              <span style={{ paddingLeft: 20 }}>
                                {moment(new Date(news.publishing_time)).format(
                                  dateFormat
                                )}
                              </span>
                            </div>
                          </li>
                          <li
                            className="bar-line"
                            style={{
                              margin: 0,
                              paddingLeft: "20px",
                              listStyleType: "none",
                            }}
                          >
                            |
                          </li>
                          <li
                            style={{
                              margin: 0,
                              paddingLeft: "12px",
                              listStyleType: "none",
                            }}
                          >
                            <div className="">
                              <i
                                class="fa fa-map-marker"
                                aria-hidden="true"
                              ></i>
                              <span style={{ paddingLeft: 6 }}>
                                {news.location}
                              </span>
                            </div>
                          </li>
                          <li
                            className="bar-line"
                            style={{
                              margin: 0,
                              paddingLeft: "12px",
                              listStyleType: "none",
                            }}
                          >
                            |
                          </li>
                          <li
                            style={{
                              margin: 0,
                              paddingLeft: "12px",
                              listStyleType: "none",
                            }}
                          >
                            <div className="">
                              <i class="fa fa-user" aria-hidden="true"></i>
                              <span style={{ paddingLeft: 6 }}>
                                {news.reporter}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Container>

                    <Container>
                      <h5
                        className="mt-3 mb-2"
                        dangerouslySetInnerHTML={createMarkup(
                          news?.description
                        )}
                      />
                      <div
                        className="blog_content"
                        style={{
                          fontSize: "20px",
                        }}
                        dangerouslySetInnerHTML={createMarkup(news?.content)}
                      />
                      {/* <div>
                        <span><b className="mr-2">Language: </b>{news.language}</span>
                      </div> */}
                    </Container>
                    <Helmet>
                      <title>{news.metaTitle}</title>
                      <meta name="og:title" content={news.metaTitle} />
                      <meta
                        name="twitter:title"
                        property="og:title"
                        content={news.metaTitle}
                      />
                      <meta
                        name="twitter:description"
                        content={news.metaDescription}
                      />
                      <meta
                        name="og:description"
                        content={news.metaDescription}
                      />
                      <meta name="description" content={news.metaDescription} />
                      <meta name="keywords" content={news.metaKeywords} />
                    </Helmet>
                    <div style={{ marginTop: "20px", marginLeft: "40px" }}>
                      {initialDocs?.map((item) => (
                        <p
                          onClick={() => {
                            window.open(item, "_blank");
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.color = "#2f41e0";
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.color = "black";
                          }}
                          style={{ cursor: "pointer", marginBottom: "5px" }}
                        >
                          {item.split("/").pop()}
                        </p>
                      ))}
                    </div>
                  </>
                ): (
                  <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
                  <Spin size="medium" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      </div>

      <Footer />
    </>
  );
};
export default News;
