import { addNewsApi, editNewsApi } from "../api/news";
import authErrorChecker from "../utils/authErrorChecker";
import errorValidator from "../utils/errorValidator";
import resizeImage from "../utils/resizeImage";

async function getFormData(
  {
    title,
    editionId,
    image,
    metaTitle,
    metaDescription,
    metaKeywords,
    location,
    language,
    content,
    reporter,
    publishingTime,
    youtubeUrl,
    slug,
    documents,
    deleteDocuments,
    circles,
    localities
  },
  action
) {
  const formData = new FormData();
  formData.append("title", title);
  formData.append("editionId", editionId);
  if (image?.length) {
    if (image[0].originFileObj) {
      const getNewImage = await resizeImage(image[0].originFileObj);
      formData.append("image", getNewImage);
    }
    action === "edit" && formData.append("deleteImage", false);
  } else {
    action === "edit" && formData.append("deleteImage", true);
  }
  formData.append("metaTitle", metaTitle);
  formData.append("metaDescription", metaDescription);
  formData.append("metaKeywords", metaKeywords);
  formData.append("location", location);
  formData.append("language", language);
  formData.append("content", content);
  formData.append("reporter", reporter);
  formData.append("publishing_time", publishingTime);
  //  console.log('p ',publishingTime);
  formData.append("youtube_url", youtubeUrl || "");

  if(circles?.length){
    circles.forEach((item) => formData.append('circles[]', item))
  }

  
  localities.forEach((item) => formData.append('blocks[]', item))

  // Documents
  // for (let i = 0; i < documents?.length; i++) {
  //   formData.append("documents", documents[i]);
  // }

  documents.forEach((item) => formData.append('documents[]', item))

  // // deleteDocuments
  for (let i = 0; i < deleteDocuments?.length; i++) {
    formData.append("deleteDocuments[]", deleteDocuments[i]);
  }
  if (action === "edit") {
    formData.append("slug", slug || "");
  }
  return formData;
}

export async function addNews(values, logout) {
  const formData = await getFormData(values, "add");

  try {
    const responseJSON = await addNewsApi(formData);
    if (responseJSON.data.data) {
      return responseJSON.data.data;
    }
    return null;
  } catch (error) {
    // 403 HOLD, 401 -> error catcher
    authErrorChecker(error, logout);

    // Additional Error checker
    errorValidator(error?.response?.data || error?.response);

    return false;
  }
}

export async function editNews(id, values, logout) {
  const formData = await getFormData(values, "edit");

  try {
    const responseJSON = await editNewsApi(id, formData);
    if (responseJSON.data.success) {
      return true;
    }
  } catch (error) {
    // 403 HOLD, 401 -> error catcher
    authErrorChecker(error, logout);

    // Additional Error checker
    errorValidator(error?.response?.data || error?.response);

    return false;
  }
  return null;
}
