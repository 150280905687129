import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { notification } from 'antd'
import Fade from 'react-reveal/Fade'
import noimage from '../../img/No-Photo-Available.jpg'
import { homePageData, categoriesPageData } from '../api'
import Header from '../../Header'
import Footer from '../../Footer'
import { useData } from '../../contexts/Datacontext'
import TextTruncate from 'react-text-truncate'

const Categories = (props) => {
  const [categories, setCategories] = useState([])
  const [unFilterData, setUnFilterData] = useState([])
  const history = useHistory()
  const { data } = useData()
  const isCategories = Boolean(history.location.pathname === '/categories')



  useEffect(() => {
     if(!isCategories){
      fetchHomePageData()
    }
    if(isCategories){
      handleCategoriesPageData()
    } 
  }, [isCategories])



  const fetchHomePageData = async () => {
    const res = await homePageData()
    const activeCategories =
      res?.categories?.filter((category) => category.status === 'active') ?? []
    setUnFilterData(activeCategories)
    setCategories(activeCategories)
    ///update favicon and title
    //const favicon = document.getElementById('favicon')
    //if (favicon) favicon.href = res?.logo?.Logo
  }


  const handleCategoriesPageData = async () => {
    const data = await categoriesPageData ();
    console.log('Categories Data arrived', data);
    const activeCategories = data?.data ?? []
    setUnFilterData(activeCategories)
    setCategories(activeCategories)
  }

  const refScroller = (data) => {
    if (props.location.pathname === '/home') {
      const section = document.querySelector(`#${data}`)
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      props.history.push({
        pathname: '/',
        state: { detail: data },
      })
    }
  }

  const handlePages = (data, id) => {
    history.push(`/category/${id}`)
  }

  const createMarkup = (html) => {
    // return { __html: data };
    var temporalDivElement = document.createElement('div')
    temporalDivElement.innerHTML = html
    let content =
      temporalDivElement.textContent || temporalDivElement.innerText || ''
    return content
  }

  const handleSearchChange = (evt) => {
    const searchResult = unFilterData?.filter(
      (category) =>
        category?.title
          ?.toLowerCase()
          .includes(evt.target.value.toLowerCase()) &&
        category.status === 'active'
    )
    setCategories(searchResult)
  }

  return (
    <div>
      {isCategories && <Header refScroller={refScroller} data={data?.data} />}
      {/*  ${ isCategories ? "mt-10" : ""} */}
      <div
        className={`waxon_tm_news mt-10`}
        id="categories"
        ref={props.refProp}
      >
        <div className="container">
          <div className="waxon_tm_main_title">
            <div className="title">
              <h3>
                {' '}
                Products & Outlets<span className="bg">Products&Outlets</span>
              </h3>
            </div>
          </div>
          { isCategories && <div style={{ paddingTop: '10px' }}>
            <input
              onChange={handleSearchChange}
              className="searchbar-homes"
              type="text"
              placeholder="Search For the Product .."
            />
          </div>}
          <div className="news_inner">
            <ul>
              {categories?.map((category, index) => (
                <li className="wow fadeInDown" data-wow-duration="0.8s">
                  <Fade top>
                    <div className="list_inner" key={index} onClick={() => handlePages(category.slug, category.id)}>
                      <div className="image">
                        {category.image ===
                        'https://avaneeshkoyikkara.com/null' ? (
                          <>
                            <img
                              onClick={() => handlePages(category.slug, category.id)}
                              src={noimage}
                              alt="no"
                              style={{
                                width: '100%',
                                height: '260px',
                                backgroundImage: `url(${noimage})`,
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              onClick={() => handlePages(category.slug, category.id)}
                              src={category.image}
                              alt="cover"
                              style={{ width: '100%', height: '260px' }}
                            />
                            <div
                              className="main"
                              onClick={() => handlePages(category.slug, category.id)}
                              style={{
                                backgroundImage: `url(${category.image})`,
                                width: '100%',
                                height: '260px',
                              }}
                            />
                          </>
                        )}
                      </div>
                      <div className="details">
                        <h3 className="title">
                          <a onClick={() => handlePages(category.slug, category.id)}>
                            {/* {category.title} */}
                            <TextTruncate
                              line={1}
                              element="span"
                              truncateText="…"
                              text={category.title}
                            />
                          </a>
                        </h3>
                        <div>
                          <div>
                            <TextTruncate
                              line={2}
                              element="div"
                              truncateText="…"
                              text={createMarkup(category?.description)}
                            />
                            {/* {category.description?.length > 100 ? (
                              <div
                                dangerouslySetInnerHTML={createMarkup(
                                  category?.description
                                    ?.replace(
                                      /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
                                      ''
                                    )
                                    .slice(0, 60) + '...'
                                )}
                              />
                            ) : (
                              <div
                                dangerouslySetInnerHTML={createMarkup(
                                  category.description
                                )}
                              />
                            )} */}
                          </div>
                        </div>
                        <div
                          className="waxon_tm_read_more text-right"
                          style={{ marginTop: 'auto' }}
                        >
                          <button
                            style={{
                              backgroundColor: '#4ab7ff',
                              padding: '10px',
                              border: 'none',
                              borderRadius: '10px',
                              margin: '10px',
                              color: '#fff',
                            }}
                            onClick={() => handlePages(category.slug, category.id)}
                          >
                            Show
                          </button>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </li>
              ))}
              {history.location.pathname !== '/categories' &&
                categories.length > 6 && (
                  <div className="waxon_tm_read_more text-center">
                    <button
                      style={{
                        backgroundColor: '#32CD32',
                        padding: '10px',
                        border: 'none',
                        borderRadius: '10px',
                        margin: '10px',
                        color: '#fff',
                      }}
                      onClick={() => history.push('/categories')}
                    >
                      View All
                    </button>
                  </div>
                )}
              {!categories.length && (
                <div>
                  <div className="row">
                    <div className="col d-flex justify-content-center align-items-center">
                      <h3 style={{ fontWeight: 'bold' }}>No Category Found</h3>
                    </div>
                  </div>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
      {isCategories && <Footer />}
    </div>
  )
}

export default Categories
