import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import noimage from '../../img/No-Photo-Available.jpg'
import { homePageData, editionsPageData } from '../api'
import Header from '../../Header'
import Footer from '../../Footer'
import { useData } from '../../contexts/Datacontext'
import TextTruncate from 'react-text-truncate'

const Editions = (props) => {
  const [editions, setEditions] = useState([])
  const [unFilterData, setUnFilterData] = useState([])
  const history = useHistory()
  const { data } = useData()
  const isEditions = Boolean(history.location.pathname === '/editions')


  useEffect(() => {
     if(!isEditions){
      fetchHomePageData()
    }
    if(isEditions){
      handleEditionsPageData()
    } 
  }, [isEditions])

  console.log('editions are',editions)


  const fetchHomePageData = async () => {
    const res = await homePageData()
    const activeEditions =
      res?.editions?.filter((edition) => edition.status === 'active') || []
    setUnFilterData(activeEditions)
    setEditions(activeEditions)
    ///update favicon and title
    //const favicon = document.getElementById('favicon')
    //if (favicon) favicon.href = res?.logo?.Logo
  }

  const handleEditionsPageData = async () => {
    const data = await editionsPageData ();
    const activeEditions = data?.data ?? []
    setUnFilterData(activeEditions)
    setEditions(activeEditions)
  }

  const refScroller = (data) => {
    if (props.location.pathname === '/home') {
      const section = document.querySelector(`#${data}`)
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      props.history.push({
        pathname: '/',
        state: { detail: data },
      })
    }
  }

  const handlePages = (data,id) => {
    history.push(`/edition/${id}`)
  }

  const createMarkup = (html) => {
    var temporalDivElement = document.createElement('div')
    temporalDivElement.innerHTML = html
    let content =
      temporalDivElement.textContent || temporalDivElement.innerText || ''
    return content
    // return { __html: data }
  }

  const handleSearchChange = (evt) => {
    const searchResult = unFilterData?.filter(
      (edition) =>
        edition?.title
          ?.toLowerCase()
          .includes(evt.target.value.toLowerCase()) &&
        edition.status === 'active'
    )
    setEditions(searchResult)
  }

  return (
    <div>
      {isEditions && <Header refScroller={refScroller} data={data?.data} />}
      {/* ${ isEditions ? "mt-10" : ""} */}
      <div className={`waxon_tm_news mt-10`} id="editions" ref={props.refProp}>
        <div className="container">
          <div className="waxon_tm_main_title">
            <div className="title">
              <h3>
                {' '}
                Editions & News<span className="bg">Editions&News</span>
              </h3>
            </div>
          </div>
          {isEditions && <div style={{ paddingTop: '10px' }}>
            <input
              onChange={handleSearchChange}
              className="searchbar-homes"
              type="text"
              placeholder="Search For the Edition .."
            />
          </div>}
          <div className="news_inner">
            <ul>
              {editions?.map((edition, index) => (
                <li className="wow fadeInDown" data-wow-duration="0.8s">
                  <Fade top>
                    <div className="list_inner" key={index} onClick={() => handlePages(edition.slug, edition.id)}>
                      <div className="image">
                        {edition.image ===
                        'https://zurlet.com/null' ? (
                          <>
                            <img
                              onClick={() => handlePages(edition.slug, edition.id)}
                              src={noimage}
                              alt="no"
                              style={{
                                width: '100%',
                                height: '260px',
                                backgroundImage: `url(${noimage})`,
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              onClick={() => handlePages(edition.slug, edition.id)}
                              src={edition.image}
                              alt="cover"
                              style={{ width: '100%', height: '260px' }}
                            />
                            <div
                              className="main"
                              onClick={() => handlePages(edition.slug, edition.id)}
                              style={{
                                backgroundImage: `url(${edition.image})`,
                                width: '100%',
                                height: '260px',
                              }}
                            />
                          </>
                        )}
                      </div>
                      <div className="details">
                        <h3 className="title">
                          <a onClick={() => handlePages(edition.slug, edition.id)}>
                            <TextTruncate
                              line={1}
                              element="span"
                              truncateText="…"
                              text={edition.title}
                            />
                            {/* {edition.title} */}
                          </a>
                        </h3>

                        {/* <div
                          dangerouslySetInnerHTML={createMarkup(
                            edition?.description
                          )}
                        /> */}
                        <TextTruncate
                          line={2}
                          element="div"
                          truncateText="…"
                          text={createMarkup(edition?.description)}
                        />

                        {/* <div>
                          <div>
                            {edition.content?.length > 100 ? (
                              <div
                                dangerouslySetInnerHTML={createMarkup(
                                  edition.content
                                    .replace(
                                      /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
                                      ''
                                    )
                                    .slice(0, 60) + '...'
                                )}
                              />
                            ) : (
                              <div
                                dangerouslySetInnerHTML={createMarkup(
                                  edition.content
                                )}
                              />
                            )}
                          </div>
                        </div> */}
                        <div
                          className="waxon_tm_read_more text-right"
                          style={{ marginTop: 'auto' }}
                        >
                          <button
                            style={{
                              backgroundColor: '#4ab7ff',
                              padding: '10px',
                              border: 'none',
                              borderRadius: '10px',
                              margin: '10px',
                              color: '#fff',
                            }}
                            onClick={() => handlePages(edition.slug, edition.id)}
                          >
                            Show
                          </button>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </li>
              ))}
              {history.location.pathname !== '/editions' &&
                editions.length > 6 && (
                  <div
                    className="waxon_tm_read_more text-center"
                    style={{ marginTop: 'auto' }}
                  >
                    <button
                      style={{
                        backgroundColor: '#32CD32',
                        padding: '10px',
                        border: 'none',
                        borderRadius: '10px',
                        margin: '10px',
                        color: '#fff',
                      }}
                      onClick={() => history.push('/editions')}
                    >
                      View All
                    </button>
                  </div>
                )}
              {!editions.length && (
                <div>
                  <div className="row">
                    <div className="col d-flex justify-content-center align-items-center">
                      <h3 style={{ fontWeight: 'bold' }}>No Editions Found</h3>
                    </div>
                  </div>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
      {isEditions && <Footer />}
    </div>
  )
}

export default Editions
