import React, { useState, useEffect } from 'react'
import Header from '../../Header'
import Footer from '../../Footer'
import { homePageData } from '../api'
import { Link } from 'react-router-dom'
import HeroImg from '../../img/article.jpg'
import { Helmet } from 'react-helmet'
import { NotFound } from '../404Error'
import { useData } from '../../contexts/Datacontext'
import { viewPolicyById } from '../../api/policy'
import errorValidator from '../../utils/errorValidator'

const Policy = (props) => {
  const [policyData, setPolicyData] = useState({})
  const [policyType, setPolicyType] = useState('')
  const id = props.match.params.id

  const { data } = useData()
  //   props.match.params.id

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    handlePageData()
    getPolicyData(id)
  }, [id])

  const getPolicyType = (type) => {
    switch (type) {
      case 1:
        return 'Policies'
      case 2:
        return 'Terms & Conditions'
      // case 3:
      //   return "Return&Refund";
      // case 4:
      //   return "Copyright";
      default:
        return
    }
  }

  const getPolicyData = async (id) => {
    try {
      const resp = await viewPolicyById(id)

      setPolicyData(resp.data.data)
      const type = getPolicyType(resp.data.data.type)
      setPolicyType(type)
    } catch (err) {
      // Additional Error checker
      errorValidator(err.response.data || err.response)
    }
  }

  const handlePageData = async () => {
    const res = await homePageData()
    //const favicon = document.getElementById('favicon')
    //if (favicon) favicon.href = res?.logo?.Logo
  }

  const refScroller = (data) => {
    if (props.location.pathname === '/home') {
      const section = document.querySelector(`#${data}`)
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      props.history.push({
        pathname: '/',
        state: { detail: data },
      })
    }
  }

  return (
    <>
      <Header refScroller={refScroller} data={data?.data} />

      <div className="waxon_tm_blog_single">
        <div className="hero classic" style={{ marginBottom: 10 }}>
          <div
            className="articleImg"
            style={{ backgroundImage: `url(${HeroImg})` }}
          />
        </div>
      </div>
      <div className="policy-content">
        <div className="container">
          <div className="waxon_tm_main_title">
            <div className="title" style={{ paddingTop: '30px' }}>
              <h3>
                {policyType}
                <span className="bg">{policyType.replace(/\s+/g, '')}</span>
              </h3>

              {/* <div
            // style={{ paddingTop: "50px" }}
            dangerouslySetInnerHTML={createMarkup(
              page?.published_data?.description
            )}
          /> */}
            </div>
          </div>
          <h2>{policyData.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: policyData.content }}></div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Policy
