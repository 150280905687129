/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { viewAllEditions, viewNews } from "../../../../api/news";
import { useAuth } from "../../../../contexts/auth-context";
import CustomModal from "../../../../shared/Modal";
import authErrorChecker from "../../../../utils/authErrorChecker";
import errorValidator from "../../../../utils/errorValidator";
import FormNews from "./Form";
import queryString from 'query-string'
import { viewAllCircles } from "../../../../api/circles";
import { viewAllBlocks } from "../../../../api/blocks";

const AddEditNews = ({ id, setShow, show, setNewsId }) => {
  const { logout } = useAuth();

  const [editions, setEditions] = useState([]);
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(false);
  const [circles, setCircles] = useState([]);
  const [blocksData, setBlocksData] = useState([]);

  //   Fetching pages
  const fetchingEditions = async () => {
    try {
      const res = await viewAllEditions();
      console.log("response editions", res);
      setEditions(res.data.data);
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);

      // Additional Error checker
      errorValidator(err.response.data || err.response);
    }
  };

  //   Fetching Circles
  const fetchingCircles = async () => {
    try {
      const res = await viewAllCircles();
      setCircles(res.data.data);
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);

      // Additional Error checker
      errorValidator(err.response.data || err.response);
    }
  };

  const getBlocks = async (circleIds) => {
    const paramsCircles = queryString.stringify(
      { circles: circleIds },
      { arrayFormat: "bracket" }
    );

    try {
      const res = await viewAllBlocks(paramsCircles);
      if (res.data.data) {
        setBlocksData(res.data.data);
      } else {
        setBlocksData([]);
      }
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);
      setBlocksData([]);

      // Additional Error checker
      errorValidator(err.response.data || err.response);
    }
  };

  // Fetch News by Id
  const fetchNewsById = async (newsId) => {
    setLoading(true);
    try {
      const res = await viewNews(newsId);
      console.log("response news", res.data.data);
      setNews(res.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("err in news api", err);

      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);

      // Additional Error checker
      errorValidator(err.response.data || err.response);
    }
  };

  useEffect(() => {
    if (show) {
      fetchingEditions();
      fetchingCircles();

      if (id) {
        fetchNewsById(id);
      }
    } else {
      setNewsId(null);
      setNews(null);
    }
  }, [show, id]);

  return (
    <CustomModal
      title={news ? "Edit News" : "Add News"}
      size="lg"
      setShow={setShow}
      show={show}
    >
      <div style={{ minHeight: 400 }}>
        {loading ? (
          <div className="element-centering-position">
            <Spinner animation="border" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
          </div>
        ) : news ? (
          <FormNews
            setShow={setShow}
            data={news}
            editions={editions}
            circleData={circles}
            getBlocks={getBlocks}
            blocksData={blocksData}
          />
        ) : (
          <FormNews
            setShow={setShow}
            editions={editions}
            circleData={circles}
            getBlocks={getBlocks}
            blocksData={blocksData}
          />
        )}
      </div>
    </CustomModal>
  );
};

export default AddEditNews;
