import { addArticleApi, editArticleApi } from '../api/article'
import authErrorChecker from '../utils/authErrorChecker'
import errorValidator from '../utils/errorValidator'
import resizeImage from '../utils/resizeImage'

async function getFormsData(
  {
    title,
    content,
    photo,
    youtubeurl,
    publishing_Page,
    publishedDate,
    metaTitle,
    metaDescription,
    metaKeywords,
    slug,
    language,
    author,
    location,
    documents,
    deleteDocuments,
    circles,
    localities
  },
  action
) {
  console.log('tag', photo)
  const formData = new FormData()
  formData.append('title', title)
  formData.append('content', content)
  metaTitle && formData.append('metaTitle', metaTitle)
  metaDescription && formData.append('metaDescription', metaDescription)
  metaKeywords && formData.append('metaKeywords', metaKeywords)
  if (photo?.length > 0) {
    if (photo[0].originFileObj) {
      const getNewImage = await resizeImage(photo[0].originFileObj)
      formData.append('photo', getNewImage)
    }
    action === 'edit' && formData.append('deletePhoto', false)
  } else {
    action === 'edit' && formData.append('deletePhoto', true)
  }
  if (youtubeurl) {
    formData.append('youtubeurl', youtubeurl)
  }

  if (slug) {
    formData.append('slug', slug)
  }

  // formData.append('publishing_Page', publishingPage)
  formData.append('publishedDate', publishedDate)
  formData.append(
    'publishing_Page',
    publishing_Page.id ? publishing_Page.id : publishing_Page
  )
  formData.append('language', language)
  formData.append('author', author)
  formData.append('location', location)
  if(circles?.length){
    circles.forEach((item) => formData.append('circles[]', item))
  }

  
  localities.forEach((item) => formData.append('blocks[]', item))

  // documents.forEach((file) => {
  //   formData.append("documents", file);
  // });

  // deleteDocuments.forEach((file) => {
  //   formData.append("deleteDocuments", file);
  // });

  // formData.append("documents", documents);

  // Documents
  // for (let i = 0; i < documents.length; i++) {
  //   formData.append('documents', documents[i])
  // }

  documents.forEach((item) => formData.append('documents[]', item))

  // // deleteDocuments
  for (let i = 0; i < deleteDocuments.length; i++) {
    console.log(deleteDocuments[i], 'yooo')
    formData.append('deleteDocuments[]', deleteDocuments[i])
  }

  return formData
}

export async function addBlog(values, logout) {
  const formData = await getFormsData(values)
  try {
    const res = await addArticleApi(formData)
    if (res && res.data.data) return res.data.data
    return null
  } catch (error) {
    // 403 HOLD, 401 -> error catcher
    authErrorChecker(error, logout)

    // Additional Error checker
    errorValidator(error?.response?.data || error?.response)

    return false
  }
}

export async function editBlog(id, values, logout) {
  const formData = await getFormsData({ ...values }, 'edit')
  try {
    const res = await editArticleApi(id, formData)
    if (res && res.data.success) return true
    return false
  } catch (error) {
    // 403 HOLD, 401 -> error catcher
    authErrorChecker(error, logout)

    // Additional Error checker
    errorValidator(error?.response?.data || error?.response)

    return false
  }
}
