import React, { createContext, useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";

const AuthContext = createContext({});

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

const AuthProvider = (props) => {
  const [authorized, setAuthorized] = useState(false);
  const [user, setUser] = useState({});
  const [userLoading, setUserLoading] = useState(true);

  useEffect(() => {
    const getToken = window.localStorage.getItem("token");
    const userFound = JSON.parse(window.localStorage.getItem("user"));

    if (userFound) {
      setUser(userFound);
    }

    if (getToken && userFound) {
      setUserLoading(false);
      setAuthorized(true);
    } else {
      setUserLoading(false);
      setAuthorized(false);
    }
  }, []);

  const logout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("user");
    setUser({});
    setAuthorized(false);
    NotificationManager.warning("Logout Successfully");
  };

  const settingUser = (user) => {
    setUser(user);
    setAuthorized(true);
    window.localStorage.setItem("user", JSON.stringify(user));
  };

  const setToken = (token) => {
    window.localStorage.setItem("token", token);
  };

  const AuthContextValue = {
    settingUser,
    setToken,
    setAuthorized,
    logout,
    setUserLoading,
    authorized,
    user,
    userLoading,
  };

  return <AuthContext.Provider value={AuthContextValue} {...props} />;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
