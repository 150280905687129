import React, { useEffect, useState } from 'react'
import Header from '../../Header'
import Footer from '../../Footer'
import { getArticlesBySlug, homePageData } from '../api'
import noimage from '../../img/No-Photo-Available.jpg'
import HeroImg from '../../img/article.jpg'
import ReactPlayer from 'react-player'
import { Helmet } from 'react-helmet'
import { Media, Image, Container } from 'react-bootstrap'
import { useData } from '../../contexts/Datacontext'
import LoadScript from 'react-load-script'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ShareAltOutlined } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { NotificationManager } from 'react-notifications'
import copyImg from '../../img/copy.png'
import { Spin } from "antd";

const Article = (props) => {
  const [article, setArticle] = useState({})
  const [articleList, setArticleList] = useState([])
  const [initialDocs, setInitialDocs] = useState([]);
  const [flag, setFlag] = useState(false)
  const [loading, setLoading] = useState(false);
  const { data } = useData()
  const { match } = props
  const { params } = match
  const { id } = params
  const dateFormat = 'YYYY-MM-DD dddd h:mm A'
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const createMarkup = (data) => {
    return { __html: data }
  }

  useEffect(() => {
    handlePageData()
  }, [])

  const handlePageData = async () => {
    const res = await homePageData()
   // const favicon = document.getElementById('favicon')
    //if (favicon) favicon.href = res?.logo?.Logo
  }

  useEffect(() => {
    if (
      window.location.search !== undefined &&
      typeof window.location.search !== 'number'
    ) {
      setLoading(true);
      setArticle({})
      fetchArticlesBySlug(window.location.search.slice(1))
    }
  }, [id])

  console.log('feature id value is', id, article, articleList?.length)

  const fetchArticlesBySlug = async (value) => {
    const response = await getArticlesBySlug(value)
    setFlag(true);
    // if (Object.keys(response)) {
      if (response?.length>0) {
      // const articleList = response?.blogs?.filter((blog) => blog?.slug !== id)
      const articleList = response?.filter((blog) => blog?.slug !== id)
      setArticleList(articleList)
      console.log('articlesss', articleList)
      // const currentArticle = response?.blogs?.find((blog) => blog?.slug === id)
      const currentArticle = response?.find((blog) => blog?.slug === id)
      if (currentArticle) {
        setArticle(currentArticle)
        setLoading(false)
      }
    }
  }

  const handleChange = (blog) => {
    setArticle(blog)
  }

  const refScroller = (data) => {
    if (props.location.pathname === '/home') {
      const section = document.querySelector(`#${data}`)
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      props.history.push({
        pathname: '/',
        state: { detail: data },
      })
    }
  }
  // const handleAddthisLoaded = setInterval(function(){
  //   if(typeof addthis !== 'undefined') {
  //     clearInterval(handleAddthisLoaded);
  //     // eslint-disable-next-line no-undef
  //     addthis && addthis?.layers.refresh();
  //      console.log('interval');
  //     }
  //   },1000);

  const handleAddthisLoaded = () => {
    if (typeof addthis !== 'undefined') {
      setTimeout(() => {
        try {
          // eslint-disable-next-line no-undef
          addthis && addthis.layers?.refresh()
        } catch (e) {}
      }, 1000)
    }
  }

  useEffect(() => {
    if (article?.documents && !Array.isArray(article?.documents)) {
      // const arr = article.documents.slice(1, -1).split('","')
      const arr = article.documents
        .replace(/\\/g, "") // Remove backslashes
        .replace(/"/g, "") // Remove double quotes
        .slice(1, -1) // Remove the leading and trailing square brackets
        .split(","); // Convert to array
      setInitialDocs(arr);
      // article.documents = arr;
      console.log("arr value is", arr);
      // const final = article.documents.map(str => str.replace(/\\/g, ''));
    } else {
      setInitialDocs(article?.documents);
    }
  }, [article]);

  useEffect(() => {
    // Load ShareThis buttons
    if (window.__sharethis__) {
      window.__sharethis__.initialize();
    }
  }, [flag, article]);

  return (
    <>
      {/* <LoadScript
        url="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-60bb86f49dd949d8"
        onLoad={handleAddthisLoaded}
      /> */}
      <Header refScroller={refScroller} data={data?.data} />
      <div className="waxon_tm_blog_single waxon_tm_news">
        <>
          <div className="hero classic">
            <div
              className="articleImg"
              style={{ backgroundImage: `url(${HeroImg})` }}
            />
          </div>
          <div className="container bigger" style={{ paddingLeft: '0px' }}>
            <div className="row">
              <div
                className="flex-box-item col-11 col-md-5 col-lg-5 col-12 mb-4 pb-4"
                style={{
                  backgroundColor: '#f5f6fa',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  height: 'fit-content',
                }}
              >
                <h4 className="ml-2 pt-2">Related Features</h4>
                {articleList?.length > 0 ? (
                  articleList.map((article, index) => (
                    <Media
                      key={article.id}
                      style={{
                        marginLeft: '15px',
                        paddingTop: '40px',
                        borderBottom: '2px solid #d3d3d3',
                      }}
                    >
                      <Media left href="#">
                        <>
                        <Link
                              to={{
                                pathname: `/article/${article.slug}`,
                                search: window.location.search.slice(1),
                              }}
                            >
                          {article.view_type === 'image' ? (
                            <img
                              src={article.photo}
                              alt="img"
                              width="90px"
                              height="80px"
                            />
                          ) : (
                            <img
                              src={noimage}
                              alt="img"
                              width="90px"
                              height="80px"
                            />
                          )}
                          </Link>
                        </>
                      </Media>
                      <Media body>
                        <div className="row">
                          <div className="col-12">
                          <Link
                              to={{
                                pathname: `/article/${article.slug}`,
                                search: window.location.search.slice(1),
                              }}
                            >
                            <Media
                              heading
                              style={{
                                paddingLeft: '10px',
                                fontWeight: 'bold',
                                color: '#333333'
                              }}
                            >
                              {article.title}
                            </Media>
                            </Link>
                          </div>
                          {/* <div className="col-12">
                            <div
                              style={{ paddingLeft: "10px" }}
                              dangerouslySetInnerHTML={createMarkup(
                                article?.content?.
                                  replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, "")
                                  .slice(0, 50) + "..."
                              )}
                            />
                          </div> */}
                          <div className="col d-flex">
                            {/* <button
                              onClick={() => handleChange(article, index)}
                              to={{
                                pathname: `/article/${article.slug}`,
                                state: article.id,
                              }}
                              style={{
                                backgroundColor: "#4ab7ff",
                                marginLeft: "10px",
                                border: "none",
                                marginBottom: "4px",
                                color: "#fff",
                                borderRadius: "12px",
                              }}
                            >
                              Read More
                            </button> */}
                            <Link
                              to={{
                                pathname: `/article/${article.slug}`,
                                search: window.location.search.slice(1),
                              }}
                            >
                              <button
                                style={{
                                  backgroundColor: '#4ab7ff',
                                  marginLeft: '10px',
                                  border: 'none',
                                  marginBottom: '4px',
                                  color: '#fff',
                                  borderRadius: '12px',
                                }}
                              >
                                View
                              </button>
                            </Link>
                            {/* <p
                              style={{
                                paddingLeft: "10px",
                                fontSize: "15px",
                                fontWeight: "lighter",
                              }}
                            >
                              {article.publishedDate}
                            </p> */}
                          </div>
                        </div>
                      </Media>
                    </Media>
                  ))
                ) : (
                  <p className="text-center">No Related Features!!</p>
                )}
              </div>
              <div className="col mb-4">
              {Object.keys(article).length != 0 ? (
                <>
                <Container>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                    }}
                  >
                    <div>
                      <ShareAltOutlined
                        style={{
                          fontSize: '30px',
                          color: '#08c',
                        }}
                      />{' '}
                      Share With Your Friends
                    </div>
                    {/* <CopyToClipboard
                      text={window.location.href}
                      onCopy={() => {
                        NotificationManager.success('Link Copied')
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <CopyOutlined
                          style={{
                            marginRight: 5,
                            fontSize: '30px',
                            color: '#08c',
                          }}
                        />{' '}
                        Copy Link
                      </div>
                    </CopyToClipboard> */}
                  </div>

                  {/* <div style={{ display: 'flex' }}>
                    <div
                      // props.addThisLoaded
                      class="addthis_inline_share_toolbox"
                      data-url={window.location.href}
                      data-title="Check out this URL"
                    ></div>
                    <CopyToClipboard
                      text={window.location.href}
                      onCopy={() => {
                        NotificationManager.success('Link Copied')
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          background: '#F7CE6D',
                          height: '50px',
                          width: '100px',
                          marginLeft: '5px',
                        }}
                      >
                        <img
                          src={copyImg}
                          alt="copy_link"
                          height={23}
                          width={23}
                          style={{ marginRight: '10px' }}
                        />{' '}
                        Copy
                      </div>
                    </CopyToClipboard>
                  </div> */}
                </Container>
                <div className="sharethis-inline-share-buttons"></div>
                </>
                ): (<div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
                <Spin size="medium" />
                </div>)}
                {Object.keys(article).length != 0 && (
                    <>
                <Container>
                  {!article.youtubeurl && article.photo && (
                    <div
                      style={{ marginBottom: '10px', marginTop: "20px" }}
                      className="justify-content-center"
                    >
                      <Image
                        src={article.photo}
                        style={{ width: 'auto', height: '375px' }}
                      />
                    </div>
                  )}
                  {article.youtubeurl !== null && (
                    <div className="main_infos" style={{marginTop: "20px"}}>
                      <ReactPlayer
                        controls
                        url={article.youtubeurl}
                        width="100%"
                        height="300px"
                      />
                    </div>
                  )}
                </Container>
                
                <div className="title">
                  <h3 style={{ marginLeft: '40px', marginTop: '10px' }}>{article.title}</h3>
                </div>
                <Container>
                  <div className="short_info" style={{ color: '#000' }}>
                    <ul style={{ display: 'flex' }}>
                      {article.publishedDate && (
                        <li style={{ margin: 0, listStyleType: 'none' }}>
                          <div className="list_inner">
                            <img
                              className="svg"
                              src={
                                require('../../img/svg/calendar.svg').default
                              }
                              alt="calender"
                            />
                            <span style={{ paddingLeft: 20 }}>
                              {moment(new Date(article.publishedDate)).format(
                                dateFormat
                              )}
                            </span>
                          </div>
                        </li>
                      )}
                      {article.publishedDate && (
                        <li
                          className="bar-line"
                          style={{
                            margin: 0,
                            paddingLeft: '20px',
                            listStyleType: 'none',
                          }}
                        >
                          |
                        </li>
                      )}
                      <li
                        style={{
                          margin: 0,
                          paddingLeft: '12px',
                          listStyleType: 'none',
                        }}
                      >
                        <div className="">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                          <span style={{ paddingLeft: 6 }}>
                            {article.location}
                          </span>
                        </div>
                      </li>
                      <li
                        className="bar-line"
                        style={{
                          margin: 0,
                          paddingLeft: '12px',
                          listStyleType: 'none',
                        }}
                      >
                        |
                      </li>
                      <li
                        style={{
                          margin: 0,
                          paddingLeft: '12px',
                          listStyleType: 'none',
                        }}
                      >
                        <div className="">
                          <i class="fa fa-user" aria-hidden="true"></i>
                          <span style={{ paddingLeft: 6 }}>
                            {article.author}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Container>
                
              
                  <Helmet>
                    <title>{article.metaTitle}</title>
                    <meta name="og:title" content={article.metaTitle} />
                    <meta
                      name="twitter:title"
                      property="og:title"
                      content={article.metaTitle}
                    />
                    <meta
                      name="twitter:description"
                      content={article.metaDescription}
                    />
                    <meta
                      name="og:description"
                      content={article.metaDescription}
                    />
                    <meta
                      name="description"
                      content={article.metaDescription}
                    />
                    <meta name="keywords" content={article.metaKeywords} />

                    <meta
                      property="og:image"
                      class="og-image"
                      itemprop="image"
                      content={article.photo}
                    />
                  </Helmet>
                  <Container>
                    <h5
                      className="mt-3 mb-2"
                      dangerouslySetInnerHTML={createMarkup(
                        article?.description
                      )}
                    />
                    <div
                      className="blog_content"
                      style={{
                        fontSize: '20px',
                      }}
                      dangerouslySetInnerHTML={createMarkup(article?.content)}
                    />
                    {/* <div>
                          <span><b className="mr-2">Language</b>{article.language}</span>
                        </div> */}
                    {/* <div>
                        <span><b className="mr-2">Location</b>{article.location}</span>
                    </div>
                    <div>
                        <span><b className="mr-2">Author</b>{article.author}</span>
                    </div> */}
                    {/* <div>
                        <span><b className="mr-2">Link</b>{article.youtubeurl}</span>
                      </div> */}
                  </Container>
                  <div style={{ marginTop: "20px", marginLeft: "40px" }}>
                  {initialDocs?.map((item) => (
                    <p
                    onClick = {()=>{window.open(item, '_blank')}}
                      onMouseOver={(e) => {
                        e.currentTarget.style.color = "#2f41e0";
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.color = "black";
                      }}
                      style={{ cursor: "pointer", marginBottom: "5px" }}
                    >
                      {item.split("/").pop()}
                    </p>
                  ))}
                </div>
                </>
                )}
              </div>
            </div>
          </div>
        </>
      </div>
      <Footer />
    </>
  )
}
export default Article
