import React, { useEffect, useState } from 'react'
import Header from '../../Header'
import Footer from '../../Footer'
import { getCategoriesBySlug } from '../api'
import noimage from '../../img/No-Photo-Available.jpg'
import HeroImg from '../../img/article.jpg'
import { Helmet } from 'react-helmet'
import { Media, Image, Container } from 'react-bootstrap'
import { useData } from '../../contexts/Datacontext'
import LoadScript from 'react-load-script'
import { Link } from 'react-router-dom'
import { CopyOutlined, ShareAltOutlined } from '@ant-design/icons'
import { NotificationManager } from 'react-notifications'
import CopyToClipboard from 'react-copy-to-clipboard'
import copyImg from '../../img/copy.png'
import { Spin } from "antd";

const Outlets = (props) => {
  const [outlet, setOutlets] = useState({})
  const [outletList, setOutletList] = useState([])
  const [initialDocs, setInitialDocs] = useState([]);
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data } = useData()
  const { match } = props
  const { params } = match
  const { id } = params

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const createMarkup = (data) => {
    return { __html: data }
  }

  useEffect(() => {
    if (
      window.location.search !== undefined &&
      typeof window.location.search !== 'number'
    ) {
      console.log('sssss ', window.location.search)
      setLoading(true);
      setOutlets({})
      fetchCategoriesBySlug(window.location.search.slice(1))
    }
  }, [id])

  const fetchCategoriesBySlug = async (value) => {
    const response = await getCategoriesBySlug(value)
    setFlag(true);
    console.log('outlet response', response, id)
    // if (Object.keys(response)) {
      if (response?.length>0) {
      // const outletList = response.published_data?.outlets.filter(
        const outletList = response?.filter(
        (outlet) => outlet.slug !== id && outlet.status === 'active'
      )
      setOutletList(outletList)
      // const currentExpert = response.published_data?.outlets.find(
        const currentExpert = response?.find(
        (outlet) => outlet?.slug === id && outlet?.status === 'active'
      )
      if (currentExpert) {
        setOutlets(currentExpert)
        setLoading(false);
      }
    }
  }

  const handleChange = (outlet) => {
    setOutlets(outlet)
  }

  const refScroller = (data) => {
    if (props.location.pathname === '/home') {
      const section = document.querySelector(`#${data}`)
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      props.history.push({
        pathname: '/',
        state: { detail: data },
      })
    }
  }
  // const handleAddthisLoaded = setInterval(function(){
  //   if(typeof addthis !== 'undefined') {
  //     clearInterval(handleAddthisLoaded);
  //     // eslint-disable-next-line no-undef
  //     addthis && addthis?.layers.refresh();
  //      console.log('interval');
  //     }
  //   },1000);
  const handleAddthisLoaded = () => {
    if (typeof addthis !== 'undefined') {
      setTimeout(() => {
        try {
          // eslint-disable-next-line no-undef
          addthis && addthis.layers?.refresh()
        } catch (e) {}
      }, 10)
    }
  }

  useEffect(() => {
    if (outlet?.documents && !Array.isArray(outlet?.documents)) {
      // const arr = outlet.documents.slice(1, -1).split('","')
      const arr = outlet.documents
        .replace(/\\/g, "") // Remove backslashes
        .replace(/"/g, "") // Remove double quotes
        .slice(1, -1) // Remove the leading and trailing square brackets
        .split(","); // Convert to array
      setInitialDocs(arr);
      // outlet.documents = arr;
      console.log("arr value is", arr);
      // const final = outlet.documents.map(str => str.replace(/\\/g, ''));
    } else {
      setInitialDocs(outlet?.documents);
    }
  }, [outlet]);

  console.log('outlet list', outletList)

  useEffect(() => {
    // Load ShareThis buttons
    if (window.__sharethis__) {
      window.__sharethis__.initialize();
    }
  }, [flag, outlet]);

  return (
    <>
      {/* <LoadScript
        url="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-60bb86f49dd949d8"
        onLoad={handleAddthisLoaded}
      /> */}
      <Header refScroller={refScroller} data={data?.data} />
      <div className="waxon_tm_blog_single waxon_tm_outlets">
        <>
          <div className="hero classic">
            <div
              className="articleImg"
              style={{ backgroundImage: `url(${HeroImg})` }}
            />
          </div>
          <div className="container bigger" style={{ paddingLeft: '0px' }}>
            <div className="row">
              <div
                className="flex-box-item col-11 col-md-5 col-lg-5 mb-4 pb-4"
                style={{
                  backgroundColor: '#f5f6fa',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  height: 'fit-content',
                }}
              >
                <h4 className="ml-2 pt-2">Related Outlets</h4>
                {outletList?.length > 0 ? (
                  outletList.map((outlet, index) => (
                    <Media
                      key={outlet.id}
                      style={{
                        marginLeft: '15px',
                        paddingTop: '40px',
                        borderBottom: '2px solid #d3d3d3',
                      }}
                    >
                      <Media left href="#">
                        <>
                        <Link
                              to={{
                                pathname: `/outlets/${outlet.slug}`,
                                search: window.location.search.slice(1),
                              }}
                            >
                          {outlet.image ? (
                            <img
                              src={outlet.image}
                              alt="img"
                              width="90px"
                              height="80px"
                            />
                          ) : (
                            <img
                              src={noimage}
                              alt="img"
                              width="90px"
                              height="80px"
                            />
                          )}
                          </Link>
                        </>
                      </Media>
                      <Media body>
                        <div className="row">
                          <div className="col-12">
                          <Link
                              to={{
                                pathname: `/outlets/${outlet.slug}`,
                                search: window.location.search.slice(1),
                              }}
                            >
                            <Media
                              heading
                              style={{
                                paddingLeft: '10px',
                                fontWeight: 'bold',
                                color: '#333333'
                              }}
                            >
                              {outlet.title}
                            </Media>
                            </Link>
                          </div>
                          {/* <div className="col-12">
                            <div
                              style={{ paddingLeft: "10px" }}
                              dangerouslySetInnerHTML={createMarkup(
                                outlet?.content?.
                                  replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, "")
                                  .slice(0, 50) + "..."
                              )}
                            />
                          </div> */}
                          <div className="col d-flex">
                            {/* <button
                              onClick={() => handleChange(outlet, index)}
                              to={{
                                pathname: `/outlet/${outlet.slug}`,
                                state: outlet.id,
                              }}
                              style={{
                                backgroundColor: "#4ab7ff",
                                marginLeft: "10px",
                                border: "none",
                                marginBottom: "4px",
                                color: "#fff",
                                borderRadius: "12px",
                              }}
                            >
                              Read More
                            </button> */}
                            <Link
                              to={{
                                pathname: `/outlets/${outlet.slug}`,
                                search: window.location.search.slice(1),
                              }}
                            >
                              <button
                                style={{
                                  backgroundColor: '#4ab7ff',
                                  marginLeft: '10px',
                                  border: 'none',
                                  marginBottom: '4px',
                                  color: '#fff',
                                  borderRadius: '12px',
                                }}
                              >
                                View
                              </button>
                            </Link>
                          </div>
                        </div>
                      </Media>
                    </Media>
                  ))
                ) : (
                  <p className="text-center">No Related Outlets!!</p>
                )}
              </div>
              <div className="col mb-4">
                {/* <div className="short_info">
                  <ul>
                    <li>
                      <div className="list_inner">
                        <img
                          className="svg"
                          src={require("../../img/svg/calendar.svg").default}
                          alt="calender"
                        />
                        <span>{outlet.publishing_time}</span>
                      </div>
                    </li>
                  </ul>
                </div> */}

                {/* {outlet ? ( */}
                  {Object.keys(outlet).length != 0 ? (
                  <>
                    <Container>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: '10px',
                        }}
                      >
                        <div>
                          <ShareAltOutlined
                            style={{
                              fontSize: '30px',
                              color: '#08c',
                            }}
                          />{' '}
                          Share With Your Friends
                        </div>
                        {/* <CopyToClipboard
                      text={window.location.href}
                      onCopy={() => {
                        NotificationManager.success('Link Copied')
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <CopyOutlined
                          style={{
                            marginRight: 5,
                            fontSize: '30px',
                            color: '#08c',
                          }}
                        />{' '}
                        Copy Link
                      </div>
                    </CopyToClipboard> */}
                      </div>

                      {/* <div style={{ display: 'flex' }}>
                        <div
                          // props.addThisLoaded
                          class="addthis_inline_share_toolbox"
                          data-url={window.location.href}
                          data-title="Check out this URL"
                        ></div>
                        <CopyToClipboard
                          text={window.location.href}
                          onCopy={() => {
                            NotificationManager.success('Link Copied')
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer',
                              background: '#F7CE6D',
                              height: '50px',
                              width: '100px',
                              marginLeft: '5px',
                            }}
                          >
                            <img
                              src={copyImg}
                              alt="copy_link"
                              height={23}
                              width={23}
                              style={{ marginRight: '10px' }}
                            />{' '}
                            Copy
                          </div>
                        </CopyToClipboard>
                      </div> */}
                    </Container>
                    <div className="sharethis-inline-share-buttons"></div>
                    <Container>
                      {outlet.image && (
                        <div
                          style={{ marginBottom: '10px', marginTop: "20px" }}
                          className="justify-content-center"
                        >
                          <Image
                            src={outlet.image}
                            style={{ width: 'auto', height: '375px' }}
                          />
                        </div>
                      )}
                      <div className="title">
                        <h3>{outlet.title}</h3>
                      </div>
                      <div
                        className="blog_content"
                        style={{
                          fontSize: '20px',
                        }}
                      />
                      <div
                        className="blog_content"
                        style={{
                          fontSize: '20px',
                        }}
                        dangerouslySetInnerHTML={createMarkup(outlet.content)}
                      />
                      <h5
                        className="mt-3 mb-2"
                        dangerouslySetInnerHTML={createMarkup(
                          outlet?.description
                        )}
                      />
                      <div class="desktop-number">
                        <div>
                          <span>
                            <b className="mr-2">Phone Number :</b>
                            {outlet.call_now}
                          </span>
                        </div>
                        {/* <div>
                          <span>
                            <b className="mr-2">Whatsapp :</b>
                            {outlet.whatsapp_no}
                          </span>
                        </div> */}
                        <div>
                          <span>
                            <b className="mr-2">Circles :</b>
                            {outlet?.circles?.length > 0 &&
                              outlet?.circles?.map((cur) => (
                                <span className="tag-text">
                                  {' ' + cur.name + ' '}
                                </span>
                              ))}
                          </span>
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: 10,
                        }}
                      >
                        <div
                          className="call-align"
                          style={{
                            textAlign: 'center',
                            backgroundColor: '#4ab7ff',
                            padding: '4px 8px',
                            border: 'none',
                            borderRadius: '10px',
                            color: '#fff',
                            width: '110px',
                            height: '50px',
                            lineHeight: '42px',
                          }}
                        >
                          <a
                            href={`tel://${outlet.call_now}`}
                            style={{ color: 'white', textDecoration: 'none' }}
                          >
                            <i
                              class="fa fa-phone fa-lg"
                              saria-hidden="true"
                            ></i>{' '}
                            Call Us
                          </a>
                        </div>

                        {/* <div>
                        <span><b className="mr-2">Whatsapp</b>{outlet.whatsapp_no}</span>
                      </div> */}
                        {outlet?.whatsapp_no && (
                          <div
                            style={{
                              backgroundColor: '#25D366',
                              padding: '4px 8px',
                              border: 'none',
                              borderRadius: '10px',
                              cursor: 'pointer',
                              margin: '10px 2px',
                              color: '#fff',
                              textAlign: 'center',
                              width: '110px',
                              height: '50px',
                              lineHeight: '42px',
                            }}
                          >
                            <a
                              href={`https://api.whatsapp.com/send?phone=${outlet.whatsapp_no}&amp;text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202.`}
                              style={{ color: 'white', textDecoration: 'none' }}
                            >
                              WhatsApp
                            </a>
                          </div>
                        )}

                        {outlet.website_url && (
                          <div
                            style={{
                              textAlign: 'center',
                              backgroundColor: '#4ab7ff',
                              padding: '4px 8px',
                              border: 'none',
                              borderRadius: '10px',
                              color: '#fff',
                              width: '110px',
                              height: '50px',
                              lineHeight: '42px',
                              marginRight: 2,
                            }}
                          >
                            <a
                              href={outlet.website_url}
                              target="_blank"
                              style={{ color: 'white', textDecoration: 'none' }}
                            >
                              Visit Web
                              {/* <i
                                class="fa fa-arrow-right"
                                saria-hidden="true"
                              ></i> */}
                            </a>
                          </div>
                        )}

                        {outlet.map_url && (
                          <div
                            style={{
                              textAlign: 'center',
                              backgroundColor: '#4ab7ff',
                              padding: '4px 8px',
                              border: 'none',
                              borderRadius: '10px',
                              color: '#fff',
                              width: '110px',
                              height: '50px',
                              lineHeight: '42px',
                            }}
                          >
                            <a
                              href={outlet.map_url}
                              target="_blank"
                              style={{ color: 'white', textDecoration: 'none' }}
                            >
                              Direction
                              {/* <i
                                class="fa fa-arrow-right"
                                saria-hidden="true"
                              ></i> */}
                            </a>
                          </div>
                        )}
                      </div>
                    </Container>
                    <Helmet>
                      <title>{outlet.metaTitle}</title>
                      <meta name="og:title" content={outlet.metaTitle} />
                      <meta
                        name="twitter:title"
                        property="og:title"
                        content={outlet.metaTitle}
                      />
                      <meta
                        name="twitter:description"
                        content={outlet.metaDescription}
                      />
                      <meta
                        name="og:description"
                        content={outlet.metaDescription}
                      />
                      <meta
                        name="description"
                        content={outlet.metaDescription}
                      />
                      <meta name="keywords" content={outlet.metaKeywords} />
                    </Helmet>
                    <div style={{ marginTop: "20px", marginLeft: "40px" }}>
                  {initialDocs?.map((item) => (
                    <p
                    onClick = {()=>{window.open(item, '_blank')}}
                      onMouseOver={(e) => {
                        e.currentTarget.style.color = "#2f41e0";
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.color = "black";
                      }}
                      style={{ cursor: "pointer", marginBottom: "5px" }}
                    >
                      {item.split("/").pop()}
                    </p>
                  ))}
                </div>
                  </>
                ) : (
                  // <div className="row">
                  //   <div className="col d-flex justify-content-center align-items-center">
                  //     <h3 style={{ fontWeight: 'bold' }}>No Outlet Found</h3>
                  //   </div>
                  // </div>
                  <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
                  <Spin size="medium" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      </div>
      <Footer />
    </>
  )
}
export default Outlets
