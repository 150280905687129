import { NotificationManager } from "react-notifications";

const errorValidator = (res) => {
  console.log("my-res", res);
  if (res) {
    if (res?.error) {
      NotificationManager.error(res.error);
    } else if (Array.isArray(res?.details)) {
      res.details.forEach((detail) => {
        const message = detail.Parameter;
        detail.Errors.forEach((err) => {
          for (const [key, value] of Object.entries(err)) {
            NotificationManager.error(value, message);
          }
        });
      });
    } else {
      res.details &&
        res.message &&
        NotificationManager.error(res.details, res.message);
    }
  }
  // else {
  //   NotificationManager.error("Error", "Something Went Wrong");
  // }
};

export default errorValidator;
