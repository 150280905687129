/*
    Here we will configure the axios 
*/

// import { notification } from "antd";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import objToQueryString from "../utils/objToQueryString";
// import { setLogout } from "redux/slices/authSlices";
// import store from "redux/store";
// import objToQueryString from "utils/objToQueryString";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

// runs on each request.
instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;

    
  },
  function(error) {
    console.log(error, "the-error");
    if (error.response.status === 403) {
      if (error.response.data.data.status === "hold") {
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("token");
        window.location.href = "/";
        // alert("Logout");
        //   Logout
        // store.dispatch(setLogout());
        // window.setTimeout(() => {
        //   window.location.href = '/home/user-verification';
        // }, 1000);
      } else if (error.response.data.data.status === "active") {
        const passingQuery = {};
        const { emailVerified, phoneVerified } = error.response.data.data;
        if (!emailVerified) {
          passingQuery.email = true;
        }
        if (!phoneVerified) {
          passingQuery.phone = true;
        }

        const user = JSON.parse(localStorage.getItem("user"));
        // notification.error({
        //   message: 'Error',
        //   description: 'Verification needed',
        // });
        NotificationManager.warning("Verification Needed", "Access Denied!");
        const queryParams = objToQueryString(passingQuery);
        console.log("locationss", window.location.pathname);
        window.setTimeout(() => {
          // window.location.href = `/user-verification?userId=${user.id}&${queryParams}`;

          window.location.href = `/?openProfile=true`;
        }, 1000);
      }
    }

    if (error.response.status === 401) {
      // Logout
      //   store.dispatch(setLogout());
      // alert("Logout");
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
