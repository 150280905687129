import React, { useState, useRef, useEffect } from 'react'
import { Formik } from 'formik'
import { registerSchema } from '../../formValidationSchema'
import {
  registerApi,
  userRequestValidateApi,
  userRequestVerificationApi,
} from '../../api/auth'
import NumberFormat from 'react-number-format'
import { NotificationManager } from 'react-notifications'
import errorValidator from '../../utils/errorValidator'
import { Checkbox } from 'antd'
import { Link } from 'react-router-dom'
import { viewAllPolicy } from '../../api/policy'
import { useAuth } from '../../contexts/auth-context'
import { viewAllCircles } from '../../api/circles'
import authErrorChecker from '../../utils/authErrorChecker'
import objToQueryString from '../../utils/objToQueryString'
import { viewAllBlocks } from '../../api/blocks'


const Register = ({ setAuthRoute }) => {
  const formikRef = useRef(null)
  const [termsAndConditionId, setTermsAndConditionId] = useState(null)
  const [privacyAndPolicyId, setPrivacyAndPolicyId] = useState(null)
  const [registerStep, setRegisterStep] = useState(true)
  const [emailVerificationId, setEmailVerificationId] = useState(null)
  const [phoneVerificationId, setPhoneVerificationId] = useState(null)
  const [userName, setUserName] = useState(null)
  const [userId, setUserId] = useState(null)
  const [circles, setCircles] = useState([])
  const [blocksData, setBlocksData] = useState([])

  // Getting Policies Info
  React.useEffect(() => {
    const getAllPolicy = async () => {
      try {
        const resp = await viewAllPolicy()

        resp.data.data.forEach((cur) => {
          if (cur.type === 2) {
            setTermsAndConditionId(cur.id)
          }
          if (cur.type === 1) {
            setPrivacyAndPolicyId(cur.id)
          }
        })
      } catch (err) {
        // Additional Error checker
        errorValidator(err.response.data || err.response)
      }
    }

    getAllPolicy()
  }, [])

  const { logout } = useAuth()

    //   Fetching Circles
    const fetchingCircles = async () => {
      try {
        const res = await viewAllCircles()
        setCircles(res.data.data)
      } catch (err) {
        // 403 HOLD, 401 -> error catcher
        authErrorChecker(err, logout)
  
        // Additional Error checker
        errorValidator(err.response.data || err.response)
      }
    }

    useEffect(()=>{
      fetchingCircles()
    },[])

    const getBlocks = async (circleIds) => {
      const paramsCircles = objToQueryString.stringify(
        { circles: circleIds },
        { arrayFormat: 'bracket' }
      )
  
      try {
        const res = await viewAllBlocks(paramsCircles)
        if (res.data.data) {
          setBlocksData(res.data.data)
        } else {
          setBlocksData([])
        }
      } catch (err) {
        // 403 HOLD, 401 -> error catcher
        authErrorChecker(err, logout)
        setBlocksData([])
  
        // Additional Error checker
        errorValidator(err.response.data || err.response)
      }
    }

  // In here We are verifying login user (So they can get token otp (phone and email))
  const resendVerficationHandler = async (resent) => {
    // Email verification
    if (emailVerificationId !== 'done' && resent === 'email') {
      try {
        // We will call request verification Api
        const resp = await userRequestVerificationApi({
          userName: userName,
          verificationType: 1,
        })

        setEmailVerificationId(resp.data.data.verificationId)
        NotificationManager.success(`Email: ${resp.data.message}`, 'OTP')
      } catch (err) {
        errorValidator(err.response.data || err.response)
        console.log(err.response.data)
      }
    }

    // Same process for phone verification
    if (phoneVerificationId !== 'done' && resent === 'phone') {
      try {
        const resp = await userRequestVerificationApi({
          userName: userName,
          verificationType: 2,
        })
        setPhoneVerificationId(resp.data.data.verificationId)
        NotificationManager.success(`Phone: ${resp.data.message}`, 'OTP')
      } catch (err) {
        errorValidator(err.response.data || err.response)
        console.log(err.response.data)
      }
    }
  }

  // This is for validating user by entering tokens (phone or email or both)
  const RegisterValidationHandler = async (values, setSubmitting) => {

    // In here we are checking emailVerification Id is not "done" ( "done" means we already verified in email verification case)
    if (emailVerificationId && emailVerificationId !== 'done') {
      try {
        const resp = await userRequestValidateApi({
          userId: userId,
          verificationId: emailVerificationId,
          token: values.emailToken ? values.emailToken : '',
        })

        if (resp.data.success) {
          setEmailVerificationId('done')
          delete formikRef.current.values.emailToken
        }
      } catch (err) {
        NotificationManager.error('Email: Invalid Token', 'OTP')
      }
    }

    // Same process for phone validation
    if (phoneVerificationId && phoneVerificationId !== 'done') {
      try {
        const resp = await userRequestValidateApi({
          userId: userId,
          verificationId: phoneVerificationId,
          token: values.phoneToken ? values.phoneToken : '',
        })
        if (resp.data.success) {
          setPhoneVerificationId('done')
          delete formikRef.current.values.phoneToken
        }
      } catch (err) {
        NotificationManager.error('Phone: Invalid Token', 'OTP')
      }
    }

    setSubmitting(false)
  }

  useEffect(() => {
    // If all good verfication done
    if (emailVerificationId === 'done' && phoneVerificationId === 'done') {
      NotificationManager.success(
        'Please Login with your Credentials',
        'Register Successfully'
      )
      setAuthRoute('login')
    }
  }, [emailVerificationId, phoneVerificationId])

  return (
    <>
      <p className="tc">Please Register Here To Add Your Listing</p>
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: '',
          phone: '',
          email: '',
          password: '',
          isPrivacyChecked: false,
        }}
        validationSchema={registerSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (registerStep) {
            try {
              const sendValues = {
                name: values.name,
                email: values.email,
                phone: `+91${values.phone}`,
                password: values.password,
              }
              if (values.password === values.confirmPassword) {
                const resp = await registerApi(sendValues)

                if (resp.data.success) {
                  NotificationManager.success(
                    'Kindly Check Phone SMS and Email',
                    'OTP Send Successfully'
                  )
                  setEmailVerificationId(resp.data.data.emailVerficationId)
                  setPhoneVerificationId(resp.data.data.phoneVerficationId)
                  setUserName(resp.data.data.user.email)
                  setUserId(resp.data.data.user.id)
                  setRegisterStep(false)
                  setSubmitting(false)
                  // Clearing username and password for entering 2nd step of asking token to enter

                  // Fixing problem when going token page
                }

                document.querySelector('.tokenInput').value = ''
              } else {
                NotificationManager.error("Passwords don't match", 'Passwords')
              }

              // // Clearing username and password for entering 2nd step of asking token to enter
              // formikRef.current.setValues({})

              // Fixing problem when going token page
              document.querySelector('.tokenInput').value = ''
            } catch (err) {
              errorValidator(err.response.data || err.response)
              // alert("Error", err.response.data);
            }
          } else {
            let sendingValue = {}
            if (values.emailToken) {
              sendingValue.emailToken = values.emailToken
            }
            if (values.phoneToken) {
              sendingValue.phoneToken = values.phoneToken
            }

            RegisterValidationHandler(sendingValue, setSubmitting)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="mt-2 w-100">
            {registerStep ? (
              <>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Name"
                    id="name"
                    name="name"
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    value={values.name}
                  />
                  <div className="error-msg">{errors.name && errors.name}</div>
                </div>

                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Your Username/Email"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    value={values.email}
                  />
                  <div className="error-msg">
                    {errors.email && errors.email}
                  </div>
                </div>

                <div className="form-group">
                  <NumberFormat
                    onValueChange={(e) => {
        
                      setFieldValue('phone', e.value)
                    }}
                    // customInput={FormInput}
                    // format="+1 (###) ###-####"
                    format={'+91 ##########'}
                    allowEmptyFormatting
                    mask="_"
                    // value={values.phone ? formatMobileNumber(values.phone) : ''}
                    name="phone"
                    type="text"
                  />
                  <div className="error-msg">
                    {errors.phone && errors.phone}
                  </div>
                </div>

                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Enter Your Password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    value={values.password}
                  />
                  <div className="error-msg">
                    {errors.password && errors.password}
                  </div>
                </div>

                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Enter Your Confirm Password"
                    type="password"
                    name="confirmPassword"
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    value={values.confirmPassword}
                  />
                  <div className="error-msg">
                    {errors.confirmPassword && errors.confirmPassword}
                  </div>
                </div>
                <Checkbox
                  onChange={handleChange}
                  name="isPrivacyChecked"
                  value={values.isPrivacyChecked}
                >
                  By creating an account, you agree to{' '}
                  <Link
                    to={
                      termsAndConditionId
                        ? `/policy/${termsAndConditionId}`
                        : '/#'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions
                  </Link>{' '}
                  and{' '}
                  <Link
                    to={
                      privacyAndPolicyId
                        ? `/policy/${privacyAndPolicyId}`
                        : '/#'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Policies
                  </Link>
                </Checkbox>
                <div className="error-msg">
                  {errors.isPrivacyChecked && errors.isPrivacyChecked}
                </div>

                <div className="d-flex-hr-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="primary-btn"
                  >
                    Register
                  </button>
                </div>

                <p
                  className="tc auth-switch-link"
                  onClick={() => setAuthRoute('login')}
                >
                  Signin?
                </p>
              </>
            ) : (
              <>
                <p>Verification Process</p>
                {emailVerificationId && emailVerificationId !== 'done' && (
                  <div className="form-group">
                    <input
                      className="form-control tokenInput"
                      placeholder="Email Token"
                      type="text"
                      name="emailToken"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p
                      className="tc auth-switch-link"
                      onClick={() => resendVerficationHandler('email')}
                    >
                      Resent OTP?
                    </p>
                  </div>
                )}

                {phoneVerificationId && phoneVerificationId !== 'done' && (
                  <div className="form-group">
                    <input
                      className="form-control tokenInput"
                      placeholder="Phone Token"
                      type="text"
                      name="phoneToken"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p
                      className="tc auth-switch-link"
                      onClick={() => resendVerficationHandler('phone')}
                    >
                      Resent OTP?
                    </p>
                  </div>
                )}

                <div className="d-flex-hr-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="primary-btn"
                  >
                    Verify
                  </button>
                </div>
              </>
            )}
          </form>
        )}
      </Formik>
    </>
  )
}

export default Register
