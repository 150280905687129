import React from "react";
import { Container, Image } from "react-bootstrap";

const expertsOutletsPreviewPage = ({ pageData }) => {
  const createMarkup = (data) => {
    return { __html: data };
  };


  return (
    <Container>
      {(pageData?.image || pageData?.photo) && (
        <div
          style={{ marginBottom: "10px" }}
          className="justify-content-center"
        >
          <Image
            src={pageData?.image || pageData?.photo}
            style={{ width: "500px", height: "375px" }}
          />
        </div>
      )}
      <div className="title">
        <h3>{pageData.title}</h3>
      </div>
      <div
        className="blog_content"
        style={{
          fontSize: "20px",
        }}
      />
      <div
        className="blog_content"
        style={{
          fontSize: "20px",
        }}
        dangerouslySetInnerHTML={createMarkup(pageData.content)}
      />
      <h5
        className="mt-3 mb-2"
        dangerouslySetInnerHTML={createMarkup(pageData?.description)}
      />
      <div class="desktop-number">
        <div>
          <span>
            <b className="mr-2">Phone Number :</b>
            {pageData.call_now}
          </span>
        </div>
        <div>
          <span>
            <b className="mr-2">Whatsapp :</b>
            {pageData.whatsapp_no}
          </span>
        </div>
        <div>
          <span>
            <b className="mr-2">Circles :</b>
            {pageData?.circles?.length > 0 &&
              pageData?.circles?.map((cur) => (
                <span className="tag-text">{" " + cur.name + " "}</span>
              ))}
          </span>
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          className="call-align"
          style={{
            textAlign: "center",
            backgroundColor: "#4ab7ff",
            padding: "4px 8px",
            border: "none",
            borderRadius: "10px",
            color: "#fff",
            width: "110px",
            height: "50px",
            lineHeight: "42px",
          }}
        >
          <a
            href={`tel://${pageData.call_now}`}
            style={{ color: "white", textDecoration: "none" }}
          >
            <i class="fa fa-phone fa-lg" saria-hidden="true"></i> Call Us
          </a>
        </div>

        {/* <div>
    <span><b className="mr-2">Whatsapp</b>{outlet.whatsapp_no}</span>
  </div> */}

        <div
          style={{
            backgroundColor: "#25D366",
            padding: "4px 8px",
            border: "none",
            borderRadius: "10px",
            cursor: "pointer",
            margin: "10px 2px",
            color: "#fff",
            textAlign: "center",
            width: "110px",
            height: "50px",
            lineHeight: "42px",
          }}
        >
          <a
            href={`https://api.whatsapp.com/send?phone=${pageData.whatsapp_no}&amp;text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202.`}
            style={{ color: "white", textDecoration: "none" }}
          >
            WhatsApp
          </a>
        </div>

        {pageData.website_url && (
          <div
            style={{
              textAlign: "center",
              backgroundColor: "#4ab7ff",
              padding: "4px 8px",
              border: "none",
              borderRadius: "10px",
              color: "#fff",
              width: "110px",
              height: "50px",
              lineHeight: "42px",
              marginRight: 2,
            }}
          >
            <a
              href={pageData.website_url}
              target="_blank"
              style={{ color: "white", textDecoration: "none" }}
            >
              Visit Web
              {/* <i class="fa fa-arrow-right" saria-hidden="true"></i> */}
            </a>
          </div>
        )}

        {pageData.map_url && (
          <div
            style={{
              textAlign: "center",
              backgroundColor: "#4ab7ff",
              padding: "4px 8px",
              border: "none",
              borderRadius: "10px",
              color: "#fff",
              width: "110px",
              height: "50px",
              lineHeight: "42px",
            }}
          >
            <a
              href={pageData.map_url}
              target="_blank"
              style={{ color: "white", textDecoration: "none" }}
            >
              Direction
              {/* <i class="fa fa-arrow-right" saria-hidden="true"></i> */}
            </a>
          </div>
        )}
      </div>
    </Container>
  );
};

export default expertsOutletsPreviewPage;
