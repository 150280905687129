import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import {
  ResetPasswordApi,
  userRequestValidateApi,
  userRequestVerificationApi,
} from "../../api/auth";
import errorValidator from "../../utils/errorValidator";

const ResetPassword = ({ setOpen, setAuthRoute }) => {
  const [currentFormStep, setCurrentFormStep] = useState("verification");
  const [verificationId, setVerficationId] = useState(null);
  const [userName, setUserName] = useState(null);

  // Input fields
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const [dataLoading, setDataLoading] = useState(false);

  //  First step -> User verification
  const onUserNameSubmit = async () => {
    try {
      const response = await userRequestVerificationApi({
        userName: userName,
        verificationType: 3,
      });
      if (response.data.success) {
        NotificationManager.success(`Email: ${response.data.message}`, "OTP");
        setCurrentFormStep("validation");
        setVerficationId(response.data.data.verificationId);
        setUserId(response.data.data.userId);
      }
  
    } catch (err) {
      errorValidator(err.response.data || err.response);
    }
  };

  //  Second step -> User Validation
  const onTokenSubmit = async () => {
    try {
      const resp = await userRequestValidateApi({
        userId: userId,
        verificationId: verificationId,
        token: token ? token : "",
      });

      if (resp.data.success) {
        NotificationManager.success(resp.data.message, "Success");
        setCurrentFormStep("resetPassword");
      }
    } catch (err) {
      errorValidator(err.response.data || err.response);
    }
  };

  // 3RD Step -> Reset Password
  const onResetPasswordSubmit = async () => {
    const sendData = {
      verificationId,
      password,
    };

    if (password === confirmPassword) {
      try {
        const response = await ResetPasswordApi(userId, sendData);

        if (response.data.success) {
          NotificationManager.success(response.data.message, "Success");
          setOpen(false);
          setAuthRoute("login");
          // history.replace('/home/signin');
        }
        console.log(response);
      } catch (err) {
        errorValidator(err.response.data || err.response);
      }
    } else {
      NotificationManager.error("Passwords don't match", "Error");
    }
  };

  return (
    <>
      {currentFormStep === "verification" && (
        <>
          <p style={{ margin: "20px 0" }}>Enter Details</p>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Email/ Phone Number"
              id="name"
              name="name"
              onChange={(e) => setUserName(e.target.value)}
              // onBlur={handleBlur}
              value={userName}
            />
            <div className="error-msg">
              {userName?.trim().length === 0 && "Required"}
            </div>
          </div>

          <div className="d-flex-hr-center">
            <button
              type="submit"
              disabled={dataLoading}
              className="primary-btn"
              onClick={onUserNameSubmit}
            >
              Submit
            </button>
          </div>
        </>
      )}

      {currentFormStep === "validation" && (
        <>
          <p style={{ margin: "20px 0" }}>Enter OTP</p>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Token"
              id="token"
              name="token"
              onChange={(e) => setToken(e.target.value)}
              // onBlur={handleBlur}
              value={token}
            />
            <div className="error-msg">
              {token?.trim().length === 0 && "Required"}
            </div>
          </div>

          <div className="d-flex-hr-center">
            <button
              type="submit"
              disabled={dataLoading}
              className="primary-btn"
              onClick={onTokenSubmit}
            >
              Verify
            </button>
          </div>
          <p className="tc auth-switch-link" onClick={onUserNameSubmit}>
            Resend OTP?
          </p>
        </>
      )}

      {currentFormStep === "resetPassword" && (
        <>
          <p style={{ margin: "20px 0" }}>Change Password</p>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              id="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              // onBlur={handleBlur}
              value={password}
            />
            <div className="error-msg">
              {password?.trim().length === 0 && "Required"}
            </div>
          </div>

          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Confirm Password"
              id="confirm-password"
              name="confirm-password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              // onBlur={handleBlur}
              value={confirmPassword}
            />
            <div className="error-msg">
              {confirmPassword?.trim().length === 0 && "Required"}
            </div>
          </div>

          <div className="d-flex-hr-center">
            <button
              type="submit"
              disabled={dataLoading}
              className="primary-btn"
              onClick={onResetPasswordSubmit}
            >
              Change Password
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default ResetPassword;
