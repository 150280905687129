/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { viewAllCircles } from '../../../../api/circles'
import { viewAllCategories, viewOutlet } from '../../../../api/outlet'
import { useAuth } from '../../../../contexts/auth-context'
import CustomModal from '../../../../shared/Modal'
import authErrorChecker from '../../../../utils/authErrorChecker'
import errorValidator from '../../../../utils/errorValidator'
import FormOutlet from './Form'
import { viewAllBlocks } from '../../../../api/blocks'
import queryString from 'query-string'

const AddEditOutlet = ({ id, setShow, show, setOutletId }) => {
  const { logout } = useAuth()

  const [categoriesF, setCategoriesF] = useState([])
  const [outlet, setOutlet] = useState(null)
  const [blocksData, setBlocksData] = useState([])
  const [circles, setCircles] = useState([])
  const [loading, setLoading] = useState(false)

  

  //   Fetching pages
  const fetchingCategories = async () => {
    try {
      const res = await viewAllCategories()
      setCategoriesF(res.data.data)
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout)

      // Additional Error checker
      errorValidator(err.response.data || err.response)
    }
  }

  //   Fetching Circles
  const fetchingCircles = async () => {
    try {
      const res = await viewAllCircles()
      setCircles(res.data.data)
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout)

      // Additional Error checker
      errorValidator(err.response.data || err.response)
    }
  }

  const getBlocks = async (circleIds) => {
    const paramsCircles = queryString.stringify(
      { circles: circleIds },
      { arrayFormat: 'bracket' }
    )

    try {
      const res = await viewAllBlocks(paramsCircles)
      if (res.data.data) {
        setBlocksData(res.data.data)
      } else {
        setBlocksData([])
      }
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout)
      setBlocksData([])

      // Additional Error checker
      errorValidator(err.response.data || err.response)
    }
  }

  // Fetch Outlet by Id
  const fetchOutletById = async (outletId) => {
    setLoading(true)
    try {
      const res = await viewOutlet(outletId)
      setOutlet(res.data.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)

      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout)

      // Additional Error checker
      errorValidator(err.response.data || err.response)
    }
  }

  useEffect(() => {
    if (show) {
      fetchingCategories()
      fetchingCircles()

      if (id) {
        fetchOutletById(id)
      }
    } else {
      setOutletId(null)
      setOutlet(null)
    }
  }, [show, id])

  return (
    <CustomModal
      title={outlet ? 'Edit Outlet' : 'Add Outlet'}
      size="lg"
      setShow={setShow}
      show={show}
    >
      <div style={{ minHeight: 400 }}>
        {loading ? (
          <div className="element-centering-position">
            <Spinner animation="border" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
          </div>
        ) : outlet ? (
          <FormOutlet
            setShow={setShow}
            data={outlet}
            categoriesF={categoriesF}
            circles={circles}
            getBlocks={getBlocks}
            blocksData={blocksData}
          />
        ) : (
          <FormOutlet
            setShow={setShow}
            categoriesF={categoriesF}
            circles={circles}
            getBlocks={getBlocks}
            blocksData={blocksData}
          />
        )}
      </div>
    </CustomModal>
  )
}

export default AddEditOutlet;
