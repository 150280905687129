import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { loginSchema } from '../../formValidationSchema'
import { useAuth } from '../../contexts/auth-context'
import {
  loginApi,
  userRequestVerificationApi,
  userRequestValidateApi,
} from '../../api/auth'
import errorValidator from '../../utils/errorValidator'
import { NotificationManager } from 'react-notifications'

const Login = ({ setAuthRoute, setOpen }) => {
  const formikRef = useRef(null)
  const [logInStep, setLoginStep] = useState(true)
  const [emailVerificationId, setEmailVerificationId] = useState(null)
  const [phoneVerificationId, setPhoneVerificationId] = useState(null)
  const [userId, setUserId] = useState(null)
  const [userData, setUserData] = useState({})
  const [userToken, setUserToken] = useState(null)

  const { setToken, settingUser } = useAuth()

  // The Login Handler
  const loginHandler = async (values, setSubmitting) => {
    try {
      const resp = await loginApi(values)
      const user = resp.data.data

      // Tempory state for needed for proccess
      setUserData(user)
      setUserToken(resp.data.token)
      setUserId(user.id)

      // If user verified (phone and email)
      if (user.phoneVerified && user.emailVerified) {
        settingUser(user)
        setToken(resp.data.token)

        NotificationManager.success('Login Successfully')
        setOpen(false)
      } else {
        // If user not verified either in phone or email
        loginVerficationHandler(user, values.userName)
      }

      // Clearing username and password for entering 2nd step of asking token to enter
      formikRef.current.setValues({})

      // Fixing problem when going token page
      document.querySelector('.emailTokenInput').value = ''
    } catch (err) {
      errorValidator(err?.response?.data || err?.response)
    }
    console.log(values)
    setSubmitting(false)
  }

  // In here We are verifying login user (So they can get token otp (phone and email))
  const loginVerficationHandler = async (data, userName, resent) => {
    // @data -> When submiting correct login credentials we get user data back (data means user Data)
    // @userName -> userName
    // @resent -> Whether we want to resent the otp. This function will run again

    // Clearing both Field (fixing some issue)
    formikRef.current.setFieldValue({ emailToken: '' })
    formikRef.current.setFieldValue({ phoneToken: '' })

    if (
      !data.emailVerified ||
      !data.phoneVerified ||
      (!data.emailVerified && !data.phoneVerified)
      // If the user is not verified (phone or email)
    ) {
      // Email verification
      if (!data.emailVerified && emailVerificationId !== 'done') {
        if (!data?.verifications?.email?.id || resent) {
          try {
            // We will call request verification Api
            const resp = await userRequestVerificationApi({
              userName: userName,
              verificationType: 1,
            })

            setEmailVerificationId(resp.data.data.verificationId)
            NotificationManager.success(`Email: ${resp.data.message}`, 'OTP')
          } catch (err) {
            errorValidator(err.response.data || err.response)
            console.log(err.response.data)
          }
        } else {
          // If the verification data is present itself in the user data object
          setEmailVerificationId(data?.verifications?.email?.id)
          NotificationManager.success('Email: OTP Already Sent', 'OTP')
        }
      }

      // Same process for phone verification
      if (!data.phoneVerified && phoneVerificationId !== 'done') {
        if (!data?.verifications?.phone?.id || resent) {
          try {
            const resp = await userRequestVerificationApi({
              userName: userName,
              verificationType: 2,
            })
            setPhoneVerificationId(resp.data.data.verificationId)
            NotificationManager.success(`Phone: ${resp.data.message}`, 'OTP')
          } catch (err) {
            errorValidator(err.response.data || err.response)
            console.log(err.response.data)
          }
        } else {
          NotificationManager.success('Phone: OTP Already Sent', 'OTP')
          setPhoneVerificationId(data?.verifications?.phone?.id)
        }
      }
    }
  }

  // This is for validating user by entering tokens (phone or email or both)
  const loginValidationHandler = async (values, setSubmitting) => {
    let verified = false

    // In here we are checking emailVerification Id is not "done" ( "done" means we already verified in email verification case)
    if (emailVerificationId && emailVerificationId !== 'done') {
      try {
        const resp = await userRequestValidateApi({
          userId: userId,
          verificationId: emailVerificationId,
          token: values.emailToken ? values.emailToken : '',
        })

        if (resp.data.success) {
          verified = true
          setEmailVerificationId('done')
          delete formikRef.current.values.emailToken
        }
      } catch (err) {
        verified = false
        NotificationManager.error('Email: Invalid Token', 'OTP')
      }
    }

    // Same process for phone validation
    if (phoneVerificationId && phoneVerificationId !== 'done') {
      try {
        const resp = await userRequestValidateApi({
          userId: userId,
          verificationId: phoneVerificationId,
          token: values.phoneToken ? values.phoneToken : '',
        })
        if (resp.data.success) {
          verified = true
          setPhoneVerificationId('done')
          delete formikRef.current.values.phoneToken
        }
      } catch (err) {
        verified = false
        NotificationManager.error('Phone: Invalid Token', 'OTP')
        console.log(err.response.data, 'error email validation')
      }
    }

    // If all good
    if (verified) {
      settingUser(userData)
      setToken(userToken)
      // dispatch(setUserLoading(false));
      NotificationManager.success('Login Successfully')
      setOpen(false)
    }
    setSubmitting(false)
  }

  useEffect(() => {
    // If any of validation Id exists we are going to 2nd step Token Verification
    if (emailVerificationId && emailVerificationId !== 'done') {
      setLoginStep(false)
    }
    if (phoneVerificationId && phoneVerificationId !== 'done') {
      setLoginStep(false)
    }
  }, [emailVerificationId, phoneVerificationId])

  return (
    <>
      <p className="tc">Please Login Here To Add Your Listing</p>
      <Formik
        innerRef={formikRef}
        // validationSchema={loginSchema}
        initialValues={{ userName: '', password: '' }}
        onSubmit={async (values, { setSubmitting }) => {
          // Checking with password -> If password exists we can confirm thet we are in the first step to login the user
          if (values.password) {
            loginHandler(values, setSubmitting)
          } else {
            // User validating process
            loginValidationHandler(values, setSubmitting)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="mt-2 w-100">
            {logInStep ? (
              <>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Username/Email"
                    id="userName"
                    name="userName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userName}
                  />
                  <div className="error-msg">
                    {errors.userName && errors.userName}
                  </div>
                </div>

                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Enter Your Password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <div className="error-msg">
                    {errors.password && errors.password}
                  </div>
                </div>

                <p
                  className="tr auth-switch-link"
                  onClick={() => setAuthRoute('resetPassword')}
                >
                  Forgot-Password?
                </p>

                <div className="d-flex-hr-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="primary-btn"
                  >
                    Sign In
                  </button>
                </div>
              </>
            ) : (
              <>
                <p>Verification Process</p>
                {emailVerificationId && emailVerificationId !== 'done' && (
                  <div className="form-group">
                    <input
                      className="form-control emailTokenInput"
                      placeholder="Email Token"
                      type="text"
                      name="emailToken"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                )}

                {phoneVerificationId && phoneVerificationId !== 'done' && (
                  <div className="form-group">
                    <input
                      className="form-control emailTokenInput"
                      placeholder="Phone Token"
                      type="text"
                      name="phoneToken"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                )}

                <div className="d-flex-hr-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="primary-btn"
                  >
                    Verify
                  </button>
                </div>
              </>
            )}

            {logInStep ? (
              <p
                className="tc auth-switch-link"
                onClick={() => setAuthRoute('register')}
              >
                Register?
              </p>
            ) : (
              <p
                className="tc auth-switch-link"
                onClick={() =>
                  loginVerficationHandler(userData, userData.email, true)
                }
              >
                Resend OTP?
              </p>
            )}
          </form>
        )}
      </Formik>
    </>
  )
}

export default Login
