import React, { useEffect, useState } from "react";

import Header from "../../../Header";
import Footer from "../../../Footer";
import noimage from "../../../img/No-Photo-Available.jpg";
// import { Link } from "react-router-dom";
import HeroImg from "../../../img/article.jpg";
// import { Helmet } from "react-helmet";
// import { NotFound } from "../404Error";
import {
  deleteOutletApi,
  viewAllOutlets,
  viewOutlet,
} from "../../../api/outlet";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useData } from "../../../contexts/Datacontext";
import AddEditOutlet from "./add-edit";
import { Link } from "react-router-dom";
import authErrorChecker from "../../../utils/authErrorChecker";
import errorValidator from "../../../utils/errorValidator";
import { useAuth } from "../../../contexts/auth-context";
import { Popconfirm } from "antd";
import { NotificationManager } from "react-notifications";
import Card from "../../../shared/Card";
import CustomModal from "../../../shared/Modal";
import PreviewPage from "../../../shared/PreviewPage/experts-outlets";

const UserOutlet = () => {
  const { logout } = useAuth();

  const { data } = useData();
  const [outletData, setOutletData] = useState([]);
  const [outletId, setOutletId] = useState(null);
  const [addEditOutletModal, setAddEditOutletModal] = useState(false);

  // Preview Functionality states
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [outletPreviewData, setOutletPreviewData] = useState(null);
  const [outletPreviewId, setOutletPreviewId] = useState(null);

  const refScroller = (data) => {
    console.log("scrol-data", data);
    const section = document.querySelector(`#${data}`);
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  function stripHtml(html) {
    var temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    let content =
      temporalDivElement.textContent || temporalDivElement.innerText || "";
    return content.slice(0, 100);
  }

  const getAllOutlets = async () => {
    try {
      const resp = await viewAllOutlets();
      console.log(resp.data, "the-resp");
      setOutletData(resp.data.data);
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);

      // Additional Error checker
      errorValidator(err.response.data || err.response);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    getAllOutlets();

    // fetchServicesBySlug('');
  }, [addEditOutletModal]);

  const onDelete = async (outletId) => {
    try {
      const resp = await deleteOutletApi(outletId);

      if (resp.data.success) {
        const restOutletData = [...outletData].filter(
          (outlet) => outlet.id !== outletId
        );
        setOutletData(restOutletData);
        NotificationManager.success("Outlet Deleted Successfully");
      }
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);

      // Additional Error checker
      errorValidator(err.response.data || err.response);
    }
  };

  // const handleSearchChange = () => {

  //   const searchResult = newsData.filter(
  //     (expert) =>
  //       expert?.title?.toLowerCase().includes(evt.target.value.toLowerCase()) &&
  //       expert.status === "active"
  //   );
  //   setExperts(searchResult);
  // };

  // --------------------Preview Functionality----------------------------

  const fetchOutletById = async (expertId) => {
    setLoading(true);
    try {
      const res = await viewOutlet(expertId);
      setOutletPreviewData(res.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);

      // Additional Error checker
      errorValidator(err.response.data || err.response);
    }
  };

  useEffect(() => {
    if (openPreviewModal) {
      if (outletPreviewId) {
        fetchOutletById(outletPreviewId);
      }
    }
  }, [outletPreviewId, openPreviewModal]);

  // --------------------Preview Functionality end----------------------------

  return (
    <div>
      <Header refScroller={refScroller} data={data?.data} />

      <div className="waxon_tm_blog_single">
        <div className="hero classic" style={{ marginBottom: 40 }}>
          <div
            className="articleImg"
            style={{ backgroundImage: `url(${HeroImg})` }}
          />
        </div>

        <Container>
          <div className="waxon_tm_main_title">
            <div className="title" style={{ paddingTop: "30px" }}>
              <h3>
                Outlet
                <span className="bg">Outlet</span>
              </h3>

              {/* <div
            // style={{ paddingTop: "50px" }}
            dangerouslySetInnerHTML={createMarkup(
              page?.published_data?.description
            )}
          /> */}
            </div>
          </div>
          <div
            className="add-icon-container"
            onClick={() => setAddEditOutletModal(true)}
          >
            <i className="fa fa-plus-circle add-icon"></i>
          </div>
          <AddEditOutlet
            id={outletId}
            setShow={setAddEditOutletModal}
            show={addEditOutletModal}
            setOutletId={setOutletId}
          />

          {/* Preview Page*/}
          <CustomModal
            title="Preview Page"
            size="xl"
            setShow={setOpenPreviewModal}
            show={openPreviewModal}
          >
            <div style={{ minHeight: 400 }}>
              {loading ? (
                <div className="element-centering-position">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden"></span>
                  </Spinner>
                </div>
              ) : (
                outletPreviewData && (
                  <PreviewPage pageData={outletPreviewData} />
                )
              )}
            </div>
          </CustomModal>

          <div className="waxon_tm_news" style={{ marginBottom: 40 }}>
            <div className="news_inner">
              {/* <Row>
                  <Col>1 of 2</Col>
                  <Col>2 of 2</Col>
                </Row> */}
              <Row>
                {outletData?.length > 0 ? (
                  outletData.map((outlet, j) => (
                    <Col lg="4" md="12" sm="12" xs="12">
                      <Card
                        data={outlet}
                        i={j}
                        setId={setOutletId}
                        setModal={setAddEditOutletModal}
                        onDelete={onDelete}
                        containerName="category"
                        dataName="outlets"
                        serviceName="products"
                        setPreviewId={setOutletPreviewId}
                        setOpenPreviewModal={setOpenPreviewModal}
                      />
                      {/* <li
                        className="wow fadeInDown"
                        data-wow-duration="0.8s"
                        data-wow-delay="0.4s"
                        key={j}
                        style={{ width: "auto", paddingLeft: 0 }}
                      >
                        <div
                          className="list_inner"
                          style={{
                            justifyContent: "center",
                            minHeight: "431px",
                          }}
                        >
                          <div className="image" style={{ marginTop: "-57px" }}>
                            {outlet.image ? (
                              <>
                                <img
                                  src={outlet.image}
                                  alt="main"
                                  style={{ width: "450px", height: "260px" }}
                                />
                                <div
                                  className="main"
                                  style={{
                                    backgroundImage: `url(${outlet.image})`,
                                    minWidth: "450px",
                                    // width: "100%",
                                    objectFit: "contain",
                                    height: "260px",
                                  }}
                                ></div>
                              </>
                            ) : (
                              <>
                                <img
                                  src={noimage}
                                  alt="blank"
                                  style={{
                                    width: "450px",
                                    height: "260px",
                                    backgroundImage: `url(${noimage})`,
                                  }}
                                />
                              </>
                            )}
                          </div>
                          <div className="details">
                            <h3 className="title" style={{ fontSize: 12 }}>
                              {outlet?.category?.title}
                            </h3>
                            <h3 className="title">
                              <Link
                                to={{
                                  pathname: `outlets/${outlet.slug}`,
                                  state: `products-${outlet?.category?.title}`,
                                  search: `products-${outlet?.category?.title}`,
                                }}
                              >
                                {outlet.title}
                              </Link>
                            </h3>
                            <div></div>
                            <div>
                              <div>{stripHtml(outlet.description) + "..."}</div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 15,
                              }}
                            >
                              <Link
                                style={{
                                  // clear: "both",
                                  // float: "right",
                                  backgroundColor: "#4ab7ff",
                                  padding: "10px",
                                  border: "none",
                                  borderRadius: "10px",
                                  width: "100%",
                                  // margin: "10px",
                                  color: "#fff",
                                  textAlign: "center",
                                }}
                                to={{
                                  pathname: `/outlets/${outlet.slug}`,
                                  state: `products-${outlet?.category?.title}`,
                                  search: `products-${outlet?.category?.title}`,
                                }}
                              >
                                View
                              </Link>
                              <Popconfirm
                                title="Are you sure to delete this task?"
                                onConfirm={() => onDelete(outlet?.id)}
                                // onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                              >
                                <button
                                  style={{
                                    // clear: "both",
                                    // float: "right",
                                    backgroundColor: "#4ab7ff",
                                    padding: "10px",
                                    width: "100%",
                                    border: "none",
                                    borderRadius: "10px",
                                    // margin: "10px",
                                    background: "#dc3545",
                                    color: "#fff",
                                    margin: "0 10px",
                                  }}
                                  // to={{
                                  //   pathname: `/outlet/${outlet.slug}`,
                                  //   state: outlet?.publishing_Page?.title,
                                  //   search: outlet?.publishing_Page?.title,
                                  // }}
                                >
                                  Delete
                                </button>
                              </Popconfirm>

                              <button
                                style={{
                                  // clear: "both",
                                  // float: "right",
                                  backgroundColor: "#4ab7ff",
                                  padding: "10px",
                                  border: "none",
                                  width: "100%",
                                  borderRadius: "10px",
                                  // margin: "10px",
                                  color: "#fff",
                                }}
                                onClick={() => {
                                  setOutletId(outlet.id);
                                  setAddEditOutletModal(true);
                                }}
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                        </div>
                      </li> */}
                    </Col>
                  ))
                ) : (
                  <div className="row">
                    <div className="col d-flex justify-content-center align-items-center">
                      <h3 style={{ fontWeight: "bold" }}>No Outlets Found</h3>
                    </div>
                  </div>
                )}
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default UserOutlet;
