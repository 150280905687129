/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { viewAllPages, viewArticle } from "../../../../api/article";
import { useAuth } from "../../../../contexts/auth-context";
import CustomModal from "../../../../shared/Modal";
import authErrorChecker from "../../../../utils/authErrorChecker";
import errorValidator from "../../../../utils/errorValidator";
import FormArticle from "./Form";
import { viewAllCircles } from "../../../../api/circles";
import { viewAllBlocks } from "../../../../api/blocks";
import queryString from 'query-string'

const AddEditArticle = ({ id, setArticleId, setShow, show }) => {
  const { logout } = useAuth();

  const [pages, setPages] = useState([]);
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [circles, setCircles] = useState([]);
  const [blocksData, setBlocksData] = useState([]);

  //   Fetching pages
  const fetchingPages = async () => {
    try {
      const res = await viewAllPages();
      setPages(res.data.data.filter((item) => item.status !== "hold"));
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);

      // Additional Error checker
      errorValidator(err.response.data || err.response);
    }
  };

  //   Fetching Circles
  const fetchingCircles = async () => {
    try {
      const res = await viewAllCircles();
      setCircles(res.data.data);
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);

      // Additional Error checker
      errorValidator(err.response.data || err.response);
    }
  };

  const getBlocks = async (circleIds) => {
    const paramsCircles = queryString.stringify(
      { circles: circleIds },
      { arrayFormat: "bracket" }
    );

    try {
      const res = await viewAllBlocks(paramsCircles);
      if (res.data.data) {
        setBlocksData(res.data.data);
      } else {
        setBlocksData([]);
      }
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);
      setBlocksData([]);

      // Additional Error checker
      errorValidator(err.response.data || err.response);
    }
  };

  // Fetch Article by Id
  const fetchArticleById = async (articleId) => {
    setLoading(true);
    try {
      const res = await viewArticle(articleId);
      setArticle(res.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);

      // Additional Error checker
      errorValidator(err.response.data || err.response);
    }
  };

  useEffect(() => {
    if (show) {
      fetchingPages();
      fetchingCircles();

      if (id) {
        fetchArticleById(id);
      }
    } else {
      setArticle(null);
      setArticleId(null);
    }
  }, [show, id]);

  return (
    <CustomModal
      title={article ? "Edit Feature" : "Add Feature"}
      size="lg"
      setShow={setShow}
      show={show}
    >
      <div style={{ minHeight: 400 }}>
        {loading ? (
          <div className="element-centering-position">
            <Spinner animation="border" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
          </div>
        ) : article ? (
          <FormArticle
            setShow={setShow}
            data={article}
            pages={pages}
            circleData={circles}
            getBlocks={getBlocks}
            blocksData={blocksData}
          />
        ) : (
          <FormArticle
            setShow={setShow}
            pages={pages}
            circleData={circles}
            getBlocks={getBlocks}
            blocksData={blocksData}
          />
        )}
      </div>
    </CustomModal>
  );

  //   console.log("page filter", filterData);
  //   let form = <Form pages={filterData} />;
  //   if (id) {
  //     form = (
  //       <Query url={`/api/backend/v1/blog/${id}`} loader={<Skeleton active />}>
  //         {(res) => {
  //           if (res.data) return <Form pages={filterData} data={res.data} />;
  //           return <div>No data!</div>;
  //         }}
  //       </Query>
  //     );
  //   }
  //   const title = id ? "Edit Article" : "Add Article";
  //   return (
  //     <div>
  //       <Helmet title={title} />
  //       <div className="card">
  //         <div className="card-header">
  //           <div className="utils__title">
  //             <strong>{title}</strong>
  //           </div>
  //         </div>
  //         <div className="card-body">{form}</div>
  //       </div>
  //     </div>
  //   );
};

export default AddEditArticle;
