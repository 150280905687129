import axios from "axios";
import { notification } from "antd";

const user = localStorage.getItem("user")

setInterval(function () {
  localStorage.removeItem("homepagedata");
  localStorage.removeItem("keywords");
}, 20000);

export const homePageData = () => {
  const localHomepagedata = JSON.parse(
    window.localStorage.getItem("homepagedata")
  );
  if (localHomepagedata) {
    return localHomepagedata.data;
  } else {
    return axios
      .get("/api/backend/v1/home")
      .then((response) => {
        window.localStorage.setItem(
          "homepagedata",
          JSON.stringify(response.data)
        );

        return response.data.data;
      })
      .catch((e) => console.log(e));
  }
};

export const categoriesPageData = async () => {
  const response = await axios.get("/api/backend/v1/user/categories");

  return response.data;
};

export const servicesPageData = async () => {
  const response = await axios.get("/api/backend/v1/user/services");

  return response.data;
};

export const pagesPageData = async () => {
  const response = await axios.get("/api/backend/v1/user/pages");

  return response.data;
};

export const editionsPageData = async () => {
  const response = await axios.get("/api/backend/v1/user/editions");

  return response.data;
};

// export const getArticlesBySlug = (slug, selectedCircleId, selectedBlockId) => {
//   let url
//   if (selectedCircleId && selectedBlockId) {
//     url = `/api/backend/v1/user/blog/page/${slug}?circle=${selectedCircleId}&block=${selectedBlockId}`
//   } else if (selectedCircleId) {
//     url = `/api/backend/v1/user/blog/page/${slug}?circle=${selectedCircleId}`
//   } else if (selectedBlockId) {
//     url = `/api/backend/v1/user/blog/page/${slug}?block=${selectedBlockId}`
//   } else {
//     url = `/api/backend/v1/user/blog/page/${slug}`
//   }
//   return axios
//     .get(url)
//     .then((response) => {
//       const data = response.data.data;
//       return data;
//     })
//     .catch((e) => {
//       return e;
//     });
// };

export const getArticlesBySlug = (slug, selectedCircleId, selectedBlockId, section) => {
  let url
  if(user && section!="topMenu"){    //to use this url when clicked from top navbar
    if (selectedCircleId && selectedBlockId) {
      url = `/api/backend/v1/blog/user?circle=${selectedCircleId}&block=${selectedBlockId}&page=${slug}`
    } else if (selectedCircleId) {
      url = `/api/backend/v1/blog/user?circle=${selectedCircleId}&page=${slug}`
    } else if (selectedBlockId) {
      url = `/api/backend/v1/blog/user?block=${selectedBlockId}&page=${slug}`
    } else {
      url = `/api/backend/v1/blog/user?page=${slug}`
    }
  }else{
  if (selectedCircleId && selectedBlockId) {
    url = `/api/backend/v1/blog?circle=${selectedCircleId}&block=${selectedBlockId}&page=${slug}`
  } else if (selectedCircleId) {
    url = `/api/backend/v1/blog?circle=${selectedCircleId}&page=${slug}`
  } else if (selectedBlockId) {
    url = `/api/backend/v1/blog?block=${selectedBlockId}&page=${slug}`
  } else {
    url = `/api/backend/v1/blog?page=${slug}`
  }
}
  

  const token = localStorage.getItem("token");

  const config = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  };

  return axios
    .get(url, config)
    .then((response) => {
      const data = response.data.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
};

// export const getServicesBySlug = (slug, selectedCircleId, selectedBlockId) => {
//   let url;
//   if (selectedCircleId && selectedBlockId) {
//     url = `/api/backend/v1/user/service/${slug}?circle=${selectedCircleId}&block=${selectedBlockId}`;
//   } else if (selectedCircleId) {
//     url = `/api/backend/v1/user/service/${slug}?circle=${selectedCircleId}`;
//   } else if (selectedBlockId) {
//     url = `/api/backend/v1/user/service/${slug}?block=${selectedBlockId}`;
//   } else {
//     url = `/api/backend/v1/user/service/${slug}`;
//   }

//   return axios
//     .get(url)
//     .then((response) => {
//       const data = response.data.data;
//       return data;
//     })
//     .catch((e) => {
//       return e;
//     });
// };

export const getServicesBySlug = (slug, selectedCircleId, selectedBlockId, section) => {
  console.log('section value is', section)
  let url;

  if(user && section!='topMenu'){              //to use this url when clicked from top navbar
    if (selectedCircleId && selectedBlockId) {
      url = `/api/backend/v1/experts/get/user?service=${slug}&circle=${selectedCircleId}&block=${selectedBlockId}`;
    } else if (selectedCircleId) {
      url = `/api/backend/v1/experts/get/user?service=${slug}&circle=${selectedCircleId}`;
    } else if (selectedBlockId) {
      url = `/api/backend/v1/experts/get/user?service=${slug}&block=${selectedBlockId}`;
    } else {
      url = `/api/backend/v1/experts/get/user?service=${slug}`;
    }
  }else{
    if (selectedCircleId && selectedBlockId) {
      url = `/api/backend/v1/experts/view?service=${slug}&circle=${selectedCircleId}&block=${selectedBlockId}`;
    } else if (selectedCircleId) {
      url = `/api/backend/v1/experts/view?service=${slug}&circle=${selectedCircleId}`;
    } else if (selectedBlockId) {
      url = `/api/backend/v1/experts/view?service=${slug}&block=${selectedBlockId}`;
    } else {
      url = `/api/backend/v1/experts/view?service=${slug}`;
    }
  }


  const token = localStorage.getItem("token");

  const config = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  };

  return axios
    .get(url, config)
    .then((response) => {
      const data = response.data.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
};

// export const getCategoriesBySlug = (
//   slug,
//   selectedCircleId,
//   selectedBlockId
// ) => {
//   let url;
//   if (selectedCircleId && selectedBlockId) {
//     url = `/api/backend/v1/user/category/${slug}?circle=${selectedCircleId}&block=${selectedBlockId}`;
//   } else if (selectedCircleId) {
//     url = `/api/backend/v1/user/category/${slug}?circle=${selectedCircleId}`;
//   } else if (selectedBlockId) {
//     url = `/api/backend/v1/user/category/${slug}?block=${selectedBlockId}`;
//   } else {
//     url = `/api/backend/v1/user/category/${slug}`;
//   }
//   return axios
//     .get(url)
//     .then((response) => {
//       const data = response.data.data;
//       return data;
//     })
//     .catch((e) => {
//       return e;
//     });
// };

export const getCategoriesBySlug = (
  slug,
  selectedCircleId,
  selectedBlockId,
  section
) => {
  let url;

  if(user && section!='topMenu'){        //to use this url when clicked from top navbar
    if (selectedCircleId && selectedBlockId) {
      url = `/api/backend/v1/outlets/view/user?category=${slug}&circle=${selectedCircleId}&block=${selectedBlockId}`;
    } else if (selectedCircleId) {
      url = `/api/backend/v1/outlets/view/user?category=${slug}&circle=${selectedCircleId}`;
    } else if (selectedBlockId) {
      url = `/api/backend/v1/outlets/view/user?category=${slug}&block=${selectedBlockId}`;
    } else {
      url = `/api/backend/v1/outlets/view/user?category=${slug}`;
    }
  }else{

  if (selectedCircleId && selectedBlockId) {
    url = `/api/backend/v1/outlets/view?category=${slug}&circle=${selectedCircleId}&block=${selectedBlockId}`;
  } else if (selectedCircleId) {
    url = `/api/backend/v1/outlets/view?category=${slug}&circle=${selectedCircleId}`;
  } else if (selectedBlockId) {
    url = `/api/backend/v1/outlets/view?category=${slug}&block=${selectedBlockId}`;
  } else {
    url = `/api/backend/v1/outlets/view?category=${slug}`;
  }
  }
  

  const token = localStorage.getItem("token");
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  };

  return axios
    .get(url, config)
    .then((response) => {
      const data = response.data.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
};

// export const getEditionsBySlug = (slug, selectedCircleId, selectedBlockId) => {
//   let url
//   if (selectedCircleId && selectedBlockId) {
//     url = `/api/backend/v1/user/edition/${slug}?circle=${selectedCircleId}&block=${selectedBlockId}`
//   } else if (selectedCircleId) {
//     url = `/api/backend/v1/user/edition/${slug}?circle=${selectedCircleId}`
//   } else if (selectedBlockId) {
//     url = `/api/backend/v1/user/edition/${slug}?block=${selectedBlockId}`
//   } else {
//     url = `/api/backend/v1/user/edition/${slug}`
//   }
//   return axios
//     .get(url)
//     .then((response) => {
//       const data = response.data.data;
//       return data;
//     })
//     .catch((e) => {
//       return e;
//     });
// };

export const getEditionsBySlug = (slug, selectedCircleId, selectedBlockId, section) => {
  let url
  console.log('section value', section)

  if(user && section!='topMenu'){     //to use this url when clicked from top navbar
    if (selectedCircleId && selectedBlockId) {
      url = `/api/backend/v1/newss/view/user?edition=${slug}&circle=${selectedCircleId}&block=${selectedBlockId}`
    } else if (selectedCircleId) {
      url = `/api/backend/v1/newss/view/user?edition=${slug}&circle=${selectedCircleId}`
    } else if (selectedBlockId) {
      url = `/api/backend/v1/newss/view/user?edition=${slug}&block=${selectedBlockId}`
    } else {
      url = `/api/backend/v1/newss/view/user?edition=${slug}`
    }
  }
  else{
    if (selectedCircleId && selectedBlockId) {
      url = `/api/backend/v1/newss/view?edition=${slug}&circle=${selectedCircleId}&block=${selectedBlockId}`
    } else if (selectedCircleId) {
      url = `/api/backend/v1/newss/view?edition=${slug}&circle=${selectedCircleId}`
    } else if (selectedBlockId) {
      url = `/api/backend/v1/newss/view?edition=${slug}&block=${selectedBlockId}`
    } else {
      url = `/api/backend/v1/newss/view?edition=${slug}`
    }
  }
  

  const token = localStorage.getItem("token");
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  };
  
  return axios
    .get(url, config)
    .then((response) => {
      const data = response.data.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
};

export const getMetaData = (slug) => {
  const localKeyWords = JSON.parse(window.localStorage.getItem("keywords"));
  if (localKeyWords) {
    return localKeyWords.data;
  } else {
    return axios
      .get(`/api/backend/v1/user/keywords`)
      .then((response) => {
        window.localStorage.setItem("keywords", JSON.stringify(response.data));
        return response.data.data;
      })
      .catch((e) => console.log(e));
  }
};

// Circles data
export const getCirclesData = () => {
  return axios
    .get(`/api/backend/v1/circles/view?status=active`)
    .then((response) => {
      const data = response.data.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
};

// Circles data
export const getBlocksData = (query = "") => {
  return axios
    .get(`/api/backend/v1/blocks/view?status=active&${query}`)
    .then((response) => {
      const data = response.data.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
};
