import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import {
  userRequestValidateApi,
  userRequestVerificationApi,
} from "../../api/auth";
import Header from "../../Header";
import errorValidator from "../../utils/errorValidator";
import { homePageData } from "../api";

const UserVerification = ({ location, history }) => {
  const [state, setState] = React.useState([]);
  const [userName, setUserName] = useState(null);
  const [currentFormStep, setCurrentFormStep] = useState("verification");
  const [emailVerificationId, setEmailVerificationId] = useState(null);
  const [phoneVerificationId, setPhoneVerificationId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [phoneToken, setPhoneToken] = useState(null);
  const [emailToken, setEmailToken] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = Object.fromEntries(new URLSearchParams(location.search));
    const requesterUserId = query.userId;
    setUserId(requesterUserId);
    requestVerification(false, requesterUserId);
  }, []);

  const refScroller = (data) => {
    const section = document.querySelector(`#${data}`);
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  React.useEffect(() => {
    handleHomePageData();
  }, []);

  const handleHomePageData = async () => {
    let res = await homePageData();
    setState(res);
  };

  //  First step -> User verification
  const requestVerification = async (resent, requestedUserId) => {
    const query = Object.fromEntries(new URLSearchParams(location.search));

    if (query.email === "true" && emailVerificationId !== "done") {
      if (query.email === "true" || resent) {
        try {
          // We will call request verification Api
          const resp = await userRequestVerificationApi({
            userName: userName
              ? userName
              : requestedUserId
              ? requestedUserId
              : "",
            verificationType: 1,
          });
          setEmailVerificationId(resp.data.data.verificationId);
          setUserId(resp.data.data.userId);
          NotificationManager.success(`Email: ${resp.data.message}`, "OTP");

          setCurrentFormStep("validation");
        } catch (err) {
          errorValidator(err.response.data || err.response);
        }
      }
    }

    if (query.phone === "true" && phoneVerificationId !== "done") {
      if (query.phone === "true" || resent) {
        try {
          // We will call request verification Api
          const resp = await userRequestVerificationApi({
            userName: userName
              ? userName
              : requestedUserId
              ? requestedUserId
              : "",
            verificationType: 2,
          });
          setPhoneVerificationId(resp.data.data.verificationId);
          setUserId(resp.data.data.userId);
          NotificationManager.success(`Phone: ${resp.data.message}`, "OTP");

          setCurrentFormStep("validation");
        } catch (err) {
          errorValidator(err.response.data || err.response);
        }
      }
    }
  };

  //   Second Step on Token Submit
  const onTokenSubmit = async () => {
    let verified = false;

    if (emailVerificationId && emailVerificationId !== "done") {
      try {
        const resp = await userRequestValidateApi({
          userId: userId,
          verificationId: emailVerificationId,
          token: emailToken ? emailToken : "",
        });

        if (resp.data.success) {
          verified = true;
          setEmailVerificationId("done");
          setEmailToken(null);
        }
      } catch (err) {
        verified = false;
        console.log(err.response.data, "error email validation");
        NotificationManager.error("Email: Invalid Token", "OTP");
      }
    }
    if (phoneVerificationId && phoneVerificationId !== "done") {
      try {
        const resp = await userRequestValidateApi({
          userId: userId,
          verificationId: phoneVerificationId,
          token: phoneToken ? phoneToken : "",
        });
        if (resp.data.success) {
          verified = true;
          setPhoneVerificationId("done");
          setPhoneToken(null);
        }
      } catch (err) {
        verified = false;
        NotificationManager.error("Phone: Invalid Token", "OTP");
      }
    }

    if (verified) {
      // dispatch(setUserLoading(false));
      history.replace("/");
      NotificationManager.success("Verification Successfully");
    }
  };

  return (
    <>
      <Header refScroller={refScroller} data={state} />

      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} sm={12} md={12} lg={6}>
            <div
              style={{
                marginTop: 150,
                marginBottom: 20,
                padding: 20,
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
            >
              <h3 style={{ margin: "20px 0" }}>User Verification</h3>

              {currentFormStep === "verification" && (
                <>
                  <p style={{ margin: "20px 0" }}>Enter Details</p>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email/ Phone Number"
                      id="name"
                      name="name"
                      onChange={(e) => setUserName(e.target.value)}
                      value={userName}
                    />
                    <div className="error-msg">
                      {userName?.trim().length === 0 && "Required"}
                    </div>
                  </div>

                  <div className="d-flex-hr-center">
                    <button
                      type="submit"
                      // disabled={dataLoading}
                      className="primary-btn"
                      onClick={requestVerification}
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}

              {currentFormStep === "validation" && (
                <>
                  <p>Verification Process</p>
                  {emailVerificationId && emailVerificationId !== "done" && (
                    <div className="form-group">
                      <input
                        className="form-control emailTokenInput"
                        placeholder="Email Token"
                        type="text"
                        name="emailToken"
                        onChange={(e) => setEmailToken(e.target.value)}
                      />
                    </div>
                  )}

                  {phoneVerificationId && phoneVerificationId !== "done" && (
                    <div className="form-group">
                      <input
                        className="form-control emailTokenInput"
                        placeholder="Phone Token"
                        type="text"
                        name="phoneToken"
                        onChange={(e) => setPhoneToken(e.target.value)}
                      />
                    </div>
                  )}

                  <div className="d-flex-hr-center">
                    <button
                      type="submit"
                      //   disabled={isSubmitting}
                      className="primary-btn"
                      onClick={onTokenSubmit}
                    >
                      Verify
                    </button>
                  </div>
                  <p
                    className="tc auth-switch-link"
                    onClick={() => requestVerification(true, userId)}
                  >
                    Resend OTP?
                  </p>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserVerification;
