// We are using Yup for validating forms
import * as Yup from 'yup'

const passRegEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/

Yup.addMethod(Yup.string, 'password', function () {
  return this.test(
    'password',
    'Password must be between 6 to 20 characters with at least one numeric digit, one uppercase and one lowercase letter',
    (value) => {
      console.log('valuessss', value)
      if (value || value?.length > 0) {
        return passRegEx.test(value)
      }
      return true
    }
  )
})

const regExMobNo = /^\+(1)\s\(\d{3}\)\s\d{3}-\d{4}/

Yup.addMethod(Yup.string, 'phone', function () {
  return this.test(
    'phone',
    'Phone number is not valid (Eg +1 (484) 298-9675)',
    (value) => regExMobNo.test(value)
  )
})

const regExUrl =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_-]+=[a-zA-Z0-9-%]+&?)?$/
Yup.addMethod(Yup.string, 'url', function () {
  return this.test('url', 'Url is not valid', (value) => regExUrl.test(value))
})

// Login Schema
export const loginSchema = Yup.object().shape({
  userName: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
})

// Register Schema
export const registerSchema = Yup.object().shape({
  name: Yup.string().required('required'),
  email: Yup.string().required('required'),
  password: Yup.string().password().required('Required'),
  phone: Yup.string().required('Required'),
  confirmPassword: Yup.string().password().required('Required'),
  isPrivacyChecked: Yup.boolean().oneOf([true], 'Required'),
})

// User Edit Schema
export const userEditSchema = Yup.object().shape({
  name: Yup.string().required('required'),
  email: Yup.string().required('required'),
  // password: Yup.string().password().required("Required"),
  phone: Yup.string().required('Required'),
  // confirmPassword: Yup.string().password().required("Required"),
})

export const articleSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  content: Yup.string().required('Required'),
  publishedDate: Yup.date().required('Required'),
  publishing_Page: Yup.string().required('Required'),
  language: Yup.string().required('Required'),
  // youtubeurl: Yup.string().required('Required'),
  photo: Yup.array().nullable(),
  // metaTitle: Yup.string().required("Required"),
  // metaDescription: Yup.string().required("Required"),
  // metaKeywords: Yup.string().required("Required"),
  slug: Yup.string(),
  author: Yup.string().required('Required'),
  location: Yup.string().required('Required'),
})

export const expertSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  services: Yup.array().min(1).required('Required'),
  circles: Yup.array().min(1).required('Required'),
  localities: Yup.array().min(1).required('Required'),
  // metaTitle: Yup.string().required("Required"),
  // metaDescription: Yup.string().required("Required"),
  // metaKeywords: Yup.string().required("Required"),
  callNow: Yup.string()
    .matches(
      '^(\\+\\d{1,3}( )?)?((\\(\\d{3}\\))|\\d{3})[- .]?\\d{3}[- .]?\\d{4}$',
      'Invalid No'
    )
    .required('Required'),
    
  // whatsappNo: Yup.string()
  //   .matches(
  //     '^(\\+\\d{1,3}( )?)?((\\(\\d{3}\\))|\\d{3})[- .]?\\d{3}[- .]?\\d{4}$',
  //     'Invalid No'
  //   )
  //   .required('Required'),
  // mapUrl: Yup.string().url('Invalid Url'),
  description: Yup.string().required('Required'),
  slug: Yup.string(),
  status: Yup.string(),
  // websiteUrl: Yup.string().required('Required'),
})

export const newsSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  editionId: Yup.number().required('Required'),
  // metaTitle: Yup.string().required("Required"),
  // metaDescription: Yup.string().required("Required"),
  // metaKeywords: Yup.string().required("Required"),
  location: Yup.string().required('Required'),
  language: Yup.string().required('Required'),
  reporter: Yup.string().required('Required'),
  // publishingTime: Yup.date().required('Required'),
  content: Yup.string().required('Required'),
  // youtubeUrl: Yup.string().required('Required'),
  slug: Yup.string(),
})

export const outletSchema = Yup.object().shape({
  title: Yup.string().required('Required.'),
  categories: Yup.array().min(1).required('Required'),
  circles: Yup.array().min(1).required('Required'),
  localities: Yup.array().min(1).required('Required'),
  // metaTitle: Yup.string().required("Required"),
  // metaDescription: Yup.string().required("Required"),
  // metaKeywords: Yup.string().required("Required"),
  callNow: Yup.string()
    .matches(
      '^(\\+\\d{1,3}( )?)?((\\(\\d{3}\\))|\\d{3})[- .]?\\d{3}[- .]?\\d{4}$',
      'Invalid No'
    )
    .required('Required'),
  // whatsappNo: Yup.string()
  //   .matches(
  //     '^(\\+\\d{1,3}( )?)?((\\(\\d{3}\\))|\\d{3})[- .]?\\d{3}[- .]?\\d{4}$',
  //     'Invalid No'
  //   )
  //   .required('Required'),
  // publishingTime: Yup.date().required('Required'),
  // websiteUrl: Yup.string().required('Required'),
  // mapUrl: Yup.string().url('Invalid Url'),
  description: Yup.string().required('Required'),
  status: Yup.string(),
  slug: Yup.string(),
})
