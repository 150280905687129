import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import './css/plugins.css'
import 'antd/dist/antd.css' // or 'antd/dist/antd.less'

import './App.css'
import Footer from './Footer'
import Article from './Components/Article/index'
import ArticleList from './Components/ArticleList/index'
import About from './Components/Home/About'
import Pages from './Components/Home/Pages'
import Categories from './Components/categories/Categories'
import Category from './Components/categories/Category'
import Editions from './Components/editions/Editions'
import Edition from './Components/editions/Edition'
import News from './Components/editions/News'
import Services from './Components/services/Services'
import Service from './Components/services/Service'
import Home from './Components/Home/index'
import { DataProvider } from './contexts/Datacontext'
import Experts from './Components/services/Experts'
import Outlets from './Components/categories/Outlets'

// Dashboard
import { useAuth } from './contexts/auth-context'

import Dashboard from './Components/Dashboard'
import UserVerification from './Components/UserVerfication'
import UserArticle from './Components/Dashboard/UserArticle'
import UserExpert from './Components/Dashboard/UserExpert'
import UserNews from './Components/Dashboard/UsersNews'
import UserOutlet from './Components/Dashboard/UserOutlet'
import Policy from './Components/policy'

// import * as LoadScript from 'react-load-script';

function App() {
  const {
    authorized,
    // setAuthorized,
    // settingUser,
    userLoading,
    // setUserLoading,
  } = useAuth()

  // useEffect(() => {
  //   const getToken = window.localStorage.getItem("token");
  //   settingUser(JSON.parse(window.localStorage.getItem("user")));

  //   if (getToken) {
  //     setAuthorized(true);
  //     setUserLoading(false);
  //   } else {
  //     setAuthorized(false);
  //     setUserLoading(false);
  //   }
  // }, []);

  console.log('loadinggg', userLoading)

  return (
    <DataProvider>
      {!userLoading ? (
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            {/* pages */}
            <Route exact path="/pages" component={Pages} />
            <Route exact path="/page/:id" component={ArticleList} />
            <Route
              exact
              path="/page/:id/:circleName"
              component={ArticleList}
            />
            <Route exact path="/article/:id" component={Article} />
            {/* service */}
            <Route exact path="/services" component={Services} />
            <Route exact path="/service/:id" component={Service} />
            {/* <Route exact path="/service/:id" component={Service} /> */}
            <Route
              exact
              path="/experts/:id"
              render={(routeProps) => <Experts {...routeProps} />}
            />
            {/* category */}
            <Route exact path="/categories" component={Categories} />
            <Route exact path="/category/:id" component={Category} />
            <Route
              exact
              path="/category/:id/:circleName"
              component={Category}
            />
            <Route
              exact
              path="/edition/:id/:circleName"
              component={Edition}
            />
            <Route exact path="/outlets/:id" component={Outlets} />
            {/* edition */}
            <Route exact path="/editions" component={Editions} />
            <Route exact path="/edition/:id" component={Edition} />
            <Route exact path="/news/:id" component={News} />
            {/* about */}
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Footer} />
            <Route
              exact
              path="/user-verification"
              component={UserVerification}
            />
            <Route exact path="/policy/:id" component={Policy} />

            {/* Dashboard */}
            {authorized ? (
              <>
                <Route exact path="/user-article" component={UserArticle} />
                <Route exact path="/user-expert" component={UserExpert} />
                <Route exact path="/user-news" component={UserNews} />
                <Route exact path="/user-outlet" component={UserOutlet} />
              </>
            ) : (
              <Redirect to="/" />
            )}
          </Switch>
        </Router>
      ) : (
        <>Loading</>
      )}
    </DataProvider>
  )
}

export default App
